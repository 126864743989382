import { IOnboardingStep } from '@/types'
import { Ternary } from '@/types/input2.interface'

export const personalRevenue: IOnboardingStep = {
  id: 'personalRevenue',
  title: `Je dispose d'un revenu personnel brut d'au moins 60 000 euros par année fiscale, ou d'un portefeuille d'instruments financiers, défini comme comprenant des dépôts en espèces et des actifs financiers, qui dépasse 100 000 euros.`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.has_high_income_or_large_portfolio',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const isFinancialSector: IOnboardingStep = {
  id: 'isFinancialSector',
  title: `Je travaille ou j'ai travaillé dans le secteur financier pendant au moins un an en occupant une position professionnelle exigeant une connaissance des transactions ou des services envisagés, ou j’ai occupé un poste de direction pendant au moins 12 mois dans une personne morale telle que mentionnée ci-dessous.`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.has_relevant_financial_experience',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const doFinancialOperations: IOnboardingStep = {
  id: 'doFinancialOperations',
  title: `J'ai effectué des transactions d'une taille significative sur les marchés des capitaux à une fréquence moyenne de 10 par trimestre, au cours des quatre trimestres précédents.`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.has_significant_capital_market_transactions',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const companyCapitalIsAbove100K: IOnboardingStep = {
  id: 'companyCapitalIsAbove100K',
  title: `Les capitaux propres de votre société s’élèvent à au moins 100 000 euros.`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.company.has_minimum_equity_100k',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const companyTurnoverAbove2M: IOnboardingStep = {
  id: 'companyTurnoverAbove2M',
  title: `Le chiffre d’affaires net de votre société s’élève à au moins 2 000 000 d’euros.`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.company.has_minimum_net_revenue_2m',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const companyBilanAbove1M: IOnboardingStep = {
  id: 'companyBilanAbove1M',
  title: `Le total du bilan de votre société s’élève à au moins 1 000 000 d’euros.`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.company.has_minimum_total_balance_1m',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const toggleStep: IOnboardingStep = {
  id: 'toggleStep',
  isAutoSubmit: true,
  completedCondition: (formData: any) => {
    return formData.exactness_conditions === Ternary.true && formData.exactness_profile === Ternary.true
  },
  fields: [
    {
      id: 'exactness_conditions',
      component: 'toggle-button',
      isVirtual: true,
      default: () => Ternary.unknown,
      componentProps: {
        commentText:
          "<div class='grid gap-2'><p>Je suis conscient(e) qu'en demandant à être traité(e) comme un Investisseur averti pour les besoins des services de financement participatif fournis par Fundimmo, je ne bénéficierai pas du processus d'investissement spécifique mis en place pour les Investisseurs non avertis, en particulier :</p> <ul class='list-disc ml-4 grid gap-2'><li>le test de connaissances, la simulation pour supporter les pertes, et l'avertissement sur les risques pour les investissements importants conformément à l'article 21 du Règlement 2020/1503.</li><li>la période de réflexion de 4 jours conformément à l'article 21 du Règlement 2020/1503.</li></ul></div>",
        isRequired: true,
        centered: false
      }
    },
    {
      id: 'exactness_profile',
      component: 'toggle-button',
      isVirtual: true,
      default: () => Ternary.unknown,
      componentProps: {
        commentText: `Je confirme l'exactitude des informations transmises.`,
        isRequired: true,
        centered: false
      }
    }
  ]
}

export const ONBOARDING_KNOWLEDGEABLE_PP_STEPS = [
  personalRevenue,
  isFinancialSector,
  doFinancialOperations,
  toggleStep
]

export const ONBOARDING_KNOWLEDGEABLE_PM_STEPS = [
  companyCapitalIsAbove100K,
  companyTurnoverAbove2M,
  companyBilanAbove1M,

  toggleStep
]
