<template>
  <div class="text-gray-500">
    {{ props.content }}
  </div>
</template>

<script setup lang="ts">
// Refacto - Voir pour réutiliser l'interface de input.interface.ts
interface BaseHelpText {
  content: string
}

const props = withDefaults(defineProps<BaseHelpText>(), {
  content: ''
})
</script>
