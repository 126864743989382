<template>
  <div
    class="relative inline-block text-left cursor-pointer group/tooltip"
    @mouseenter="onEnter"
    @keydown.space="onEnter"
    @mouseleave="onLeave"
  >
    <BaseIcon
      v-if="!$slots['activator']"
      name="info-circle"
      class="focus:outline focus:outline-2"
      aria-label="Icon d'information"
      tabindex="0"
    />
    <div ref="tooltipElement">
      <slot name="activator" />
    </div>

    <div
      class="absolute z-50 px-4 py-3 leading-tight text-white transition bg-black rounded-md w-72 bg-opacity-95"
      :class="[
        isActive
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none hidden',
        isLeft ? 'right-0' : '',
        isTop ? '-top-2 -translate-y-full' : ''
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { BaseIcon } from '@/components/atoms'

const props = withDefaults(
  defineProps<{
    delay?: number
  }>(),
  {
    delay: 500
  }
)

const { width: screenWidth, height: screenHeight } = useWindowSize()
const tooltipElement = ref()
const isLeft = ref(false)
const isTop = ref(false)

onMounted(() => {
  const leftMarginSafety = 60
  const topMarginSafety = 60
  const { x, y, height, width } = tooltipElement.value.getBoundingClientRect()
  isLeft.value = x + width > screenWidth.value - leftMarginSafety
  isTop.value = y + height > screenHeight.value - topMarginSafety
})

const isActive = ref(false)
const leaveEvent = ref()

const onLeave = () => {
  leaveEvent.value = setTimeout(() => {
    isActive.value = false
  }, props.delay)
}

const onEnter = () => {
  if (leaveEvent.value) {
    clearTimeout(leaveEvent.value)
    leaveEvent.value = null
  }
  isActive.value = true
}
</script>
