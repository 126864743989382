import { authCheckAPI } from '@/api/authApi'
import { useUserStore } from '@/stores/user'
import { RouteLocationNormalized } from 'vue-router'

export default async ({ to }: { to: RouteLocationNormalized }) => {
  await authCheckAPI()

  const userStore = useUserStore()
  const { user, dashboard_url } = userStore

  if (dashboard_url) {
    userStore.logOut()
    window.location.href = dashboard_url
    return false
  } else if (!user?.email_validated_at && to.name !== 'auth') {
    return { name: 'auth' }
  } else if (!user?.acceptedCGU && to.name !== 'auth-register-cgu') {
    return { name: 'auth-register-cgu' }
  }

  return true
}
