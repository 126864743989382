import { toMinDigits } from '@/logic/useNumbers'

export class Moment extends Date {
  constructor(date: Date | string | undefined = new Date()) {
    super(date)
  }

  public get isValid() {
    return (
      Object.prototype.toString.call(this) === '[object Date]' &&
      !isNaN(Number(this))
    )
  }

  public get isPast(): boolean {
    return this < new Date()
  }

  public get isCurrentWeek(): boolean {
    return (
      this.weekId() === new Moment().weekId() && this.year === new Moment().year
    )
  }

  public get day(): number {
    return Number(this.toLocaleDateString('fr-FR', { day: 'numeric' }))
  }

  public get month(): number {
    return Number(this.toLocaleDateString('fr-FR', { month: 'numeric' }))
  }

  public get year(): number {
    return Number(this.toLocaleDateString('fr-FR', { year: 'numeric' }))
  }

  public weekId() {
    const target = new Date(this.valueOf())
    const dayNr = (this.getDay() + 6) % 7

    target.setDate(target.getDate() - dayNr + 3)

    const firstThursday = target.valueOf()
    target.setMonth(0, 1)

    if (target.getDay() !== 4)
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7))

    return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000)
  }

  public subtract(value: number, type: string) {
    if (type === 'years') this.setFullYear(Number(this.asYear()) - value)
    return this
  }

  public diff(value: Moment) {
    return this.getTime() - value.getTime()
  }

  public isBefore(value: Moment): boolean {
    return this <= value
  }

  public isBeforeToday(value = 0) {
    return this <= new Moment().subtract(value, 'years')
  }

  public asDay(): string {
    return this.toLocaleDateString('fr-FR', { day: 'numeric' })
  }

  public asMonth(length: 'long' | 'short' | 'narrow' = 'long'): string {
    return this.toLocaleDateString('fr-FR', { month: length }).replace('.', '')
  }

  public asYear(): string {
    return this.toLocaleDateString('fr-FR', { year: 'numeric' })
  }

  public asDayOfWeek(length: 'long' | 'short' | 'narrow' = 'long'): string {
    return this.toLocaleDateString('fr-FR', { weekday: length }).replace(
      '.',
      ''
    )
  }

  public asString(length = 'narrow') {
    switch (length) {
      case 'long':
        return `${toMinDigits(this.day)}/${toMinDigits(
          this.month
        )}/${this.asYear()}`
      case 'short':
        return `${this.day} ${this.asMonth()} ${this.asYear()}`
      default:
        return `${toMinDigits(this.day)}/${toMinDigits(
          this.month
        )}/${toMinDigits(this.year).slice(2, 4)}`
    }
  }

  public asInputDate(): string {
    return `${this.getFullYear()}-${toMinDigits(
      this.getMonth() + 1,
      2
    )}-${toMinDigits(this.getDate(), 2)}`
  }
}
