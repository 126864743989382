export const toMinDigits = (number: number | string, digits = 2) => {
  let result = Number(number).toString()
  while (result.length < digits) result = `0${number}`

  return result
}

export const toReadable = (number = 0) => {
  const parts = number.toString().split('.')

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  return parts.join('.')
}

export const formatCurrency = (
  amount?: number,
  lang?: string,
  currency?: string,
  int?: boolean
) => {
  if (typeof amount === 'number') {
    const fraction = new Intl.NumberFormat(lang || 'fr-FR', {
      style: 'currency',
      currency: currency || 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    const formatter = new Intl.NumberFormat(lang || 'fr-FR', {
      style: 'currency',
      currency: currency || 'EUR',
      minimumFractionDigits: 2
    })
    if (int) {
      return fraction.format(Math.trunc(amount))
    }
    return amount % 1 === 0 ? fraction.format(amount) : formatter.format(amount)
  }
  return '-'
}
