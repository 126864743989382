import { Ref, ref } from 'vue'
import { Ok, Err, Result, messages as errorMessages } from '@/types'

export const fetchApi = async <T>(
  path: string,
  body?: unknown,
  options?: {
    method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'
    headers?: HeadersInit
  }
): Promise<Result<T>> => {
  const optionsWithDefaults = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    ...options
  }

  const pathUrl = `${import.meta.env.VITE_API_URL}/${path}`
  const getParams =
    optionsWithDefaults.method === 'GET' && body
      ? `?${new URLSearchParams(body as string[][])}`
      : ''

  try {
    const response = await fetch(pathUrl + getParams, {
      ...optionsWithDefaults,
      body:
        optionsWithDefaults.method === 'GET' ? undefined : JSON.stringify(body)
    })

    if (!response.ok) {
      const data = await response.json()
      const errorCode: keyof typeof errorMessages = data.error?.code ?? 'api-error'

      return new Err(errorCode, {
        status: data.error?.status,
        details: data.error?.details
      })
    }

    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const data = await response.json()

      return new Ok(data, response.headers)
    } else {
      return new Ok({ success: true } as T, response.headers)
    }
  } catch (e) {
    console.error(e)
    return new Err('api-error')
  }
}

export const useAsyncData = <T>(fetch: () => Promise<T>) => {
  const isPending = ref(true)
  const response: Ref<T | null> = ref(null)

  const callFetch = async () => {
    const result = await fetch()

    response.value = result
    isPending.value = false

    return result
  }

  callFetch()

  return { isPending, response }
}
