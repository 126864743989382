import { Moment } from '@/types/moment.interface'

export interface IProjectPost {
  id: number
  content: string
  image: string
  projectId: string
  created_at: Date | string
}
// skipcq: JS-0322
export interface ProjectPost extends IProjectPost {}

// skipcq: JS-0327
export class ProjectPost implements IProjectPost {
  public created_at: Moment

  constructor(props: IProjectPost) {
    Object.assign(this, {
      ...props,
      created_at: new Moment(props.created_at)
    })
  }
}
