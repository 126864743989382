<template>
  <component
    :is="to ? 'router-link' : href ? 'a' : 'button'"
    :to="to"
    type="button"
    class="inline-flex items-center font-medium bg-transparent border-0 cursor-pointer"
    :class="baseInlineClasses(props)"
    v-bind="{
      ...attrs,
      ...(href ? { href } : {})
    }"
    :aria-label="ariaLabel"
    @click="props.inlineFunction()"
  >
    <BaseIcon v-if="iconBefore" v-bind="iconBefore" class="mr-2" :width="20" />

    <slot />

    <BaseIcon v-if="iconAfter" v-bind="iconAfter" class="ml-2" :width="20" />
  </component>
</template>

<script lang="ts" setup>
import { BaseIcon } from '@/components/atoms'
import { LocationQuery } from 'vue-router'
import { baseInlineClasses } from './InlineButtonClasses'
import {Icon} from '@/types/image.interface'

interface InlineButtonProps {
  type?: 'primary' | 'secondary'
  to?: LocationQuery
  href?: string
  ariaLabel?: string
  isUnderline?: boolean
  iconBefore?: Icon | null
  iconAfter?: Icon | null
  attrs?: object
  inlineFunction?: () => void
}

const props = withDefaults(defineProps<InlineButtonProps>(), {
  type: 'primary',
  to: undefined,
  href: undefined,
  isUnderline: false,
  ariaLabel: undefined,
  iconBefore: undefined,
  iconAfter: undefined,
  attrs: () => ({}),
  inlineFunction: () => ({})
})
</script>
