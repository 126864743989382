<template>
  <div class="relative flex w-full h-screen overflow-hidden bg-gray-100">
    <Transition
      enter-active-class="transition duration-300 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="sidebarOpened" class="z-30">
        <div
          class="absolute w-screen h-full min-h-screen bg-gray-600 opacity-75"
        />
      </div>
    </Transition>
    <Transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition duration-300 ease-in"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <div v-if="sidebarOpened" class="absolute z-40 lg:hidden">
        <div ref="sidebar" class="relative w-80">
          <div class="absolute top-0 p-1 left-full">
            <button
              class="flex items-center justify-center w-12 h-12 focus:outline-none"
              @click="openSideBar"
            >
              <svg
                class="w-6 h-6 text-white"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <!-- Mobile Sidebar -->
          <div
            class="relative flex flex-col flex-1 w-full h-screen max-w-xs pt-5 pb-4 overflow-y-auto bg-white"
          >
            <div class="flex items-center flex-shrink-0 p-4">
              <RouterLink
                class="w-44"
                :to="{ name: 'dashboard' }"
                @click="openSideBar"
              >
                <BaseIcon name="fundimmo-logo" class="text-slate" is-full />
              </RouterLink>
            </div>
            <div class="flex-1 h-0 mt-5 overflow-y-auto">
              <nav class="px-2">
                <div class="space-y-1">
                  <RouterLink
                    class="flex items-center px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                    to="/mon-compte/dashboard"
                    @click="openSideBar"
                  >
                    <svg
                      class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                    Mon tableau de bord
                  </RouterLink>
                  <RouterLink
                    class="flex items-center px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                    :to="{ name: 'subscriptionsSummary' }"
                    @click="openSideBar"
                  >
                    <svg
                      class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                      />
                    </svg>
                    Mes souscriptions
                  </RouterLink>
                  <RouterLink
                    class="flex items-center px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                    to="/projet"
                    @click="openSideBar"
                  >
                    <svg
                      class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      />
                    </svg>
                    Les projets
                  </RouterLink>
                  <RouterLink
                    class="flex items-center justify-between px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                    :to="{ name: 'transactionHistory' }"
                    @click="openSideBar"
                  >
                    <div class="flex items-center">
                      <svg
                        class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                        />
                      </svg>
                      Mes transactions
                    </div>
                  </RouterLink>
                  <RouterLink
                    class="flex items-center px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                    :to="{ name: 'postsByProject' }"
                    @click="openSideBar"
                  >
                    <svg
                      class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                      />
                    </svg>
                    Mes actualités
                  </RouterLink>
                  <template v-if="fawkesStore.user?.legal">
                    <RouterLink
                      class="flex items-center px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                      to="/mon-compte/dashboard/ubo/edit"
                      @click="openSideBar"
                    >
                      <svg
                        class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      Bénéficiaires effectifs
                    </RouterLink>
                  </template>
                  <template v-if="!fawkesStore.user?.registration_partner">
                    <RouterLink
                      class="flex items-center px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                      to="/scpi"
                      @click="openSideBar"
                    >
                      <svg
                        class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                        />
                      </svg>
                      SCPI
                    </RouterLink>
                  </template>
                  <RouterLink
                    class="flex items-center px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                    :to="{ name: 'advice' }"
                    @click="openSideBar"
                  >
                    <svg
                      class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Mon espace conseil
                  </RouterLink>
                  <RouterLink
                    class="flex items-center px-2 py-2 text-base font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                    :to="{ name: 'faq' }"
                    @click="openSideBar"
                  >
                    <svg
                      class="w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    Contactez-nous
                  </RouterLink>
                </div>
                <div class="mt-2 border-t border-gray-100" />
                <div class="py-1">
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="/mon-compte/dashboard/profile"
                    @click="openSideBar"
                  >
                    Mon profil
                  </RouterLink>
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="/mon-compte/dashboard/documents"
                    @click="openSideBar"
                  >
                    <div class="flex">
                      Mes pièces justificatives
                      <template
                        v-if="
                          fawkesStore.user?.downgraded &&
                          !fawkesStore.user?.at_least_one_pending_document
                        "
                      >
                        <svg
                          class="m-auto"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                          fill="none"
                        >
                          <path
                            d="M10.8297 7.56637L10.8296 7.56628L7.48175 1.61452C7.48171 1.61444 7.48166 1.61436 7.48162 1.61428C6.83191 0.458146 5.16842 0.458753 4.51844 1.61428L1.17044 7.56628L1.17039 7.56637C0.53302 8.70004 1.35163 10.0994 2.65203 10.0994H9.34863C10.6486 10.0994 11.4669 8.69985 10.8297 7.56637ZM6.10003 7.80001C6.10003 7.82653 6.08949 7.85196 6.07074 7.87072C6.05199 7.88947 6.02655 7.90001 6.00003 7.90001C5.97351 7.90001 5.94807 7.88947 5.92932 7.87072C5.91057 7.85196 5.90003 7.82653 5.90003 7.80001C5.90003 7.77348 5.91057 7.74805 5.92932 7.7293C5.94807 7.71054 5.97351 7.70001 6.00003 7.70001C6.02655 7.70001 6.05199 7.71054 6.07074 7.7293C6.08949 7.74805 6.10003 7.77348 6.10003 7.80001ZM5.92932 3.5293C5.94807 3.51054 5.97351 3.50001 6.00003 3.50001C6.02655 3.50001 6.05199 3.51054 6.07074 3.52929C6.08949 3.54805 6.10003 3.57348 6.10003 3.60001V5.40001C6.10003 5.42653 6.0895 5.45196 6.07074 5.47072C6.05199 5.48947 6.02655 5.50001 6.00003 5.50001C5.97351 5.50001 5.94807 5.48947 5.92932 5.47072C5.91057 5.45196 5.90003 5.42653 5.90003 5.40001V3.60001C5.90003 3.57348 5.91057 3.54805 5.92932 3.5293L5.57577 3.17574L5.92932 3.5293Z"
                            fill="#FBBF24"
                            stroke="white"
                          />
                        </svg>
                      </template>
                    </div>
                  </RouterLink>
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="/mon-compte/dashboard/suitability_tests"
                    @click="openSideBar"
                  >
                    <div class="flex">
                      Mon questionnaire d'adéquation

                      <template
                        v-if="
                          fawkesStore.user?.suitability_test_submitted &&
                          fawkesStore.user?.investor_status !== 'compliant'
                        "
                      >
                        <svg
                          class="flex-shrink-0 mr-1.5 h-4 w-4 self-center ml-2 text-orange-400"
                          fill="currentColor"
                          stroke="#fff"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </template>
                    </div>
                  </RouterLink>
                </div>
                <div class="border-t border-gray-100" />
                <div class="py-1">
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="https://blog.fundimmo.com/"
                    @click="openSideBar"
                  >
                    Blog
                  </RouterLink>
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="https://fundimmo.com/faq"
                    @click="openSideBar"
                  >
                    Aide en ligne
                  </RouterLink>
                </div>
                <div class="border-t border-gray-100" />
                <div class="py-1">
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    rel="nofollow"
                    to="/users/sign_out"
                    @click="openSideBar"
                  >
                    Déconnexion
                  </RouterLink>
                </div>
                <StatutWarningCard />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- Desktop Sidebar -->
    <aside
      class="flex-col hidden w-64 py-5 overflow-y-auto bg-gray-900 lg:z-20 lg:flex shrink-0"
    >
      <div class="p-4 shrink-0">
        <RouterLink class="block w-44" :to="{ name: 'onboarding-home' }">
          <BaseIcon name="fundimmo-logo" class="text-white" is-full />
        </RouterLink>
      </div>
      <div class="flex flex-col flex-1 mt-5">
        <template v-if="!fawkesStore.user?.profile_completed">
          <div class="relative px-3 pt-1">
            <div class="flex items-center justify-between mb-2">
              <div>
                <span
                  class="inline-block px-2 py-1 text-xs font-semibold text-pink-600 uppercase bg-pink-100 rounded"
                >
                  profil complété
                </span>
              </div>
              <div class="text-right">
                <span class="inline-block text-xs font-semibold text-white">
                  {{ fawkesStore.user?.filled_in_profile_percentage }}%
                </span>
              </div>
            </div>
            <div
              class="flex h-2 mb-4 overflow-hidden text-xs bg-pink-100 rounded"
            >
              <div
                :style="{
                  width: `${fawkesStore.user?.filled_in_profile_percentage}%`
                }"
                class="flex flex-col justify-center text-center text-white bg-pink-500 shadow-none whitespace-nowrap"
              />
            </div>
          </div>
        </template>
        <div ref="profile" class="relative inline-block w-full px-3 text-left">
          <button
            type="button"
            class="bg-white group w-full rounded-md px-3.5 py-2 text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:bg-gray-200 focus:border-blue-300 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            @click.stop="openProfile()"
          >
            <div class="flex items-center justify-between w-full">
              <div class="flex items-center justify-between space-x-3 truncate">
                <span class="relative inline-block">
                  <span
                    class="inline-flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full"
                  >
                    <BaseAvatar
                      class="shrink-0 group-hover:text-gray-500"
                      :last-name="fawkesStore.user?.last_name"
                      :first-name="fawkesStore.user?.first_name"
                      size="small"
                      color="lightGray"
                    />
                  </span>
                  <template v-if="fawkesStore.user?.skip_validation">
                    <svg
                      class="absolute top-0 right-0 block w-3 h-3 text-white bg-blue-400 rounded-full shadow-solid"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                      />
                    </svg>
                  </template>
                  <template
                    v-else-if="
                      fawkesStore.user?.downgraded &&
                      !fawkesStore.user?.at_least_one_pending_document
                    "
                  >
                    <svg
                      class="absolute top-0 right-0 block w-3 h-3 text-white rounded-full shadow-solid"
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                    >
                      <path
                        d="M10.8295 7.56637L10.8295 7.56628L7.48163 1.61452C7.48159 1.61444 7.48154 1.61436 7.4815 1.61428C6.83179 0.458146 5.1683 0.458753 4.51832 1.61428L1.17032 7.56628L1.17027 7.56637C0.532898 8.70004 1.35151 10.0994 2.65191 10.0994H9.34851C10.6485 10.0994 11.4668 8.69985 10.8295 7.56637ZM6.09991 7.80001C6.09991 7.82653 6.08937 7.85196 6.07062 7.87072C6.05187 7.88947 6.02643 7.90001 5.99991 7.90001C5.97339 7.90001 5.94795 7.88947 5.9292 7.87072C5.91044 7.85196 5.89991 7.82653 5.89991 7.80001C5.89991 7.77348 5.91044 7.74805 5.9292 7.7293C5.94795 7.71054 5.97339 7.70001 5.99991 7.70001C6.02643 7.70001 6.05187 7.71054 6.07062 7.7293C6.08937 7.74805 6.09991 7.77348 6.09991 7.80001ZM5.9292 3.5293C5.94795 3.51054 5.97339 3.50001 5.99991 3.50001C6.02643 3.50001 6.05187 3.51054 6.07062 3.52929C6.08937 3.54805 6.09991 3.57348 6.09991 3.60001V5.40001C6.09991 5.42653 6.08937 5.45196 6.07062 5.47072C6.05187 5.48947 6.02643 5.50001 5.99991 5.50001C5.97339 5.50001 5.94795 5.48947 5.9292 5.47072C5.91044 5.45196 5.89991 5.42653 5.89991 5.40001V3.60001C5.89991 3.57348 5.91044 3.54805 5.9292 3.5293L5.57564 3.17574L5.9292 3.5293Z"
                        fill="#FBBF24"
                        stroke="white"
                      />
                    </svg>
                  </template>
                  <template
                    v-else-if="
                      fawkesStore.user?.profile_completed &&
                      fawkesStore.user?.kyc_and_state_validated
                    "
                  >
                    <svg
                      class="absolute top-0 right-0 block w-3 h-3 text-white bg-green-400 rounded-full shadow-solid"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                      />
                    </svg>
                  </template>
                </span>
                <div class="flex-1 truncate">
                  <h2
                    class="flex justify-between text-sm font-medium leading-5 text-left text-gray-900"
                  >
                    {{ fawkesStore.user?.first_name }}
                  </h2>
                  <p class="text-xs leading-5 text-left text-gray-500 truncate">
                    {{ fawkesStore.user?.email }}
                  </p>
                </div>
              </div>
              <svg
                class="w-5 h-5 text-gray-400 group-hover:text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </button>
          <Transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div
              v-if="profileOpened"
              class="absolute left-0 z-10 w-full px-3 mt-1 origin-top rounded-md shadow-lg top-100"
            >
              <div
                class="bg-white rounded-md shadow-xs"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div class="py-1">
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="/mon-compte/dashboard/profile"
                  >
                    Mon profil
                  </RouterLink>
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="/mon-compte/dashboard/documents"
                  >
                    <div class="flex">
                      Mes pièces justificatives
                      <template
                        v-if="
                          fawkesStore.user?.downgraded &&
                          !fawkesStore.user?.at_least_one_pending_document
                        "
                      >
                        <svg
                          class="m-auto"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                          fill="none"
                        >
                          <path
                            d="M10.8297 7.56637L10.8296 7.56628L7.48175 1.61452C7.48171 1.61444 7.48166 1.61436 7.48162 1.61428C6.83191 0.458146 5.16842 0.458753 4.51844 1.61428L1.17044 7.56628L1.17039 7.56637C0.53302 8.70004 1.35163 10.0994 2.65203 10.0994H9.34863C10.6486 10.0994 11.4669 8.69985 10.8297 7.56637ZM6.10003 7.80001C6.10003 7.82653 6.08949 7.85196 6.07074 7.87072C6.05199 7.88947 6.02655 7.90001 6.00003 7.90001C5.97351 7.90001 5.94807 7.88947 5.92932 7.87072C5.91057 7.85196 5.90003 7.82653 5.90003 7.80001C5.90003 7.77348 5.91057 7.74805 5.92932 7.7293C5.94807 7.71054 5.97351 7.70001 6.00003 7.70001C6.02655 7.70001 6.05199 7.71054 6.07074 7.7293C6.08949 7.74805 6.10003 7.77348 6.10003 7.80001ZM5.92932 3.5293C5.94807 3.51054 5.97351 3.50001 6.00003 3.50001C6.02655 3.50001 6.05199 3.51054 6.07074 3.52929C6.08949 3.54805 6.10003 3.57348 6.10003 3.60001V5.40001C6.10003 5.42653 6.0895 5.45196 6.07074 5.47072C6.05199 5.48947 6.02655 5.50001 6.00003 5.50001C5.97351 5.50001 5.94807 5.48947 5.92932 5.47072C5.91057 5.45196 5.90003 5.42653 5.90003 5.40001V3.60001C5.90003 3.57348 5.91057 3.54805 5.92932 3.5293L5.57577 3.17574L5.92932 3.5293Z"
                            fill="#FBBF24"
                            stroke="white"
                          />
                        </svg>
                      </template>
                    </div>
                  </RouterLink>
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="/mon-compte/dashboard/suitability_tests"
                  >
                    <div class="flex">
                      Mon questionnaire d'adéquation
                      <template
                        v-if="
                          fawkesStore.user?.suitability_test_submitted &&
                          fawkesStore.user?.investor_status !== 'compliant'
                        "
                      >
                        <svg
                          class="flex-shrink-0 mr-1.5 h-4 w-4 self-center ml-2 text-orange-400"
                          fill="currentColor"
                          stroke="#fff"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </template>
                    </div>
                  </RouterLink>
                </div>
                <div class="border-t border-gray-100" />
                <div class="py-1">
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    target="_blank"
                    to="https://blog.fundimmo.com/"
                  >
                    Blog
                  </RouterLink>
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    target="_blank"
                    to="https://fundimmo.com/faq"
                  >
                    Aide en ligne
                  </RouterLink>
                </div>
                <div class="border-t border-gray-100" />
                <div class="py-1">
                  <RouterLink
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    rel="nofollow"
                    data-method="delete"
                    to="/users/sign_out"
                  >
                    Déconnexion
                  </RouterLink>
                </div>
              </div>
            </div>
          </Transition>
        </div>
        <div class="overflow-y-auto">
          <nav class="px-2 mt-5 space-y-1">
            <RouterLink
              class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
              :to="{ name: 'dashboard' }"
            >
              <svg
                class="w-6 h-6 mr-4 text-gray-200 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              Mon tableau de bord
            </RouterLink>
            <RouterLink
              class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
              :to="{ name: 'subscriptionsSummary' }"
            >
              <svg
                class="w-6 h-6 mr-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z"
                  clip-rule="evenodd"
                />
              </svg>
              Mes souscriptions
            </RouterLink>
            <RouterLink
              class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
              to="/projet?scope=all_projects"
            >
              <svg
                class="w-6 h-6 mr-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                  clip-rule="evenodd"
                />
              </svg>
              Les projets
            </RouterLink>
            <RouterLink
              class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
              :to="{ name: 'transactionHistory' }"
            >
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 mr-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                  <path
                    fill-rule="evenodd"
                    d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                    clip-rule="evenodd"
                  />
                </svg>
                Mes transactions
              </div>
            </RouterLink>
            <RouterLink
              class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
              :to="{ name: 'postsByProject' }"
            >
              <svg
                class="w-6 h-6 mr-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                  clip-rule="evenodd"
                />
              </svg>
              Mes actualités
            </RouterLink>
            <template v-if="fawkesStore.user?.legal">
              <RouterLink
                class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
                to="/mon-compte/dashboard/ubo/edit"
              >
                <svg
                  class="w-6 h-6 mr-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                  />
                </svg>
                Bénéficiaires effectifs
              </RouterLink>
            </template>
            <template v-if="!fawkesStore.user?.registration_partner">
              <RouterLink
                class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
                to="/scpi"
              >
                <svg
                  class="w-6 h-6 mr-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
                SCPI
              </RouterLink>
            </template>

            <StatutWarningCard />
          </nav>
          <hr class="h-px mt-6 bg-gray-700 border-none" />
          <nav class="px-2 space-y-1">
            <RouterLink
              class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
              :to="{ name: 'advice' }"
            >
              <svg
                class="w-6 h-6 mr-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              Mon espace conseil
            </RouterLink>
            <RouterLink
              class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
              :to="{ name: 'faq' }"
            >
              <svg
                class="w-6 h-6 mr-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                />
                <path
                  d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                />
              </svg>
              Contactez-nous
            </RouterLink>
          </nav>
        </div>
      </div>
    </aside>
    <div class="flex flex-col overflow-hidden grow">
      <div class="z-20 w-full bg-white shadow shrink-0">
        <div class="px-4 mx-auto sm:px-6 lg:px-8">
          <div
            class="py-2 md:flex md:items-center md:justify-between lg:max-w-6xl lg:mx-auto"
          >
            <div class="flex-1 min-w-0">
              <div
                class="relative z-10 flex flex-shrink-0 h-24 bg-white border-b border-gray-200 lg:border-none"
              >
                <button
                  class="px-4 text-gray-400 border-r border-gray-200 focus:outline-none focus:bg-gray-100 focus:text-gray-600 lg:hidden"
                  @click="openSideBar"
                >
                  <svg
                    class="w-12 h-12 transition duration-150 ease-in-out"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h8m-8 6h16"
                    />
                  </svg>
                </button>
                <div class="flex items-center">
                  <BaseAvatar
                    class="hidden lg:block shrink-0"
                    :last-name="fawkesStore.user?.last_name"
                    :first-name="fawkesStore.user?.first_name"
                    size="large"
                  />

                  <div>
                    <div class="flex items-center">
                      <h1
                        class="ml-3 text-lg font-bold leading-7 text-gray-900 lg:text-2xl sm:leading-9 sm:truncate"
                      >
                        Bonjour {{ fawkesStore.user?.first_name }}
                      </h1>
                    </div>
                    <dl
                      class="flex flex-col ml-3 sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap"
                    >
                      <div
                        v-if="
                          fawkesStore.user?.can_invest &&
                          fawkesStore.user?.legal &&
                          fawkesStore.user?.corporate_name
                        "
                      >
                        <dt class="sr-only">Entreprise</dt>
                        <dd
                          class="flex items-center text-sm font-medium leading-5 text-gray-500 capitalize sm:mr-6"
                        >
                          <svg
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          {{ fawkesStore.user?.corporate_name }}
                        </dd>
                      </div>

                      <div
                        v-if="
                          fawkesStore.user?.can_invest ||
                          fawkesStore.user?.cached_remote_balance_in_euros
                        "
                      >
                        <dt class="sr-only">Wallet</dt>
                        <dd
                          class="flex items-center text-sm font-medium leading-5 text-gray-500 capitalize sm:mr-6"
                        >
                          <svg
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 2a1 1 0 011 1v1.323l3.954 1.582 1.599-.8a1 1 0 01.894 1.79l-1.233.616 1.738 5.42a1 1 0 01-.285 1.05A3.989 3.989 0 0115 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.715-5.349L11 6.477V16h2a1 1 0 110 2H7a1 1 0 110-2h2V6.477L6.237 7.582l1.715 5.349a1 1 0 01-.285 1.05A3.989 3.989 0 015 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.738-5.42-1.233-.617a1 1 0 01.894-1.788l1.599.799L9 4.323V3a1 1 0 011-1zm-5 8.274l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L5 10.274zm10 0l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L15 10.274z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          {{
                            formatCurrency(
                              fawkesStore.user?.cached_remote_balance_in_euros
                            )
                          }}
                        </dd>
                      </div>
                      <div v-if="fawkesStore.user?.can_invest">
                        <div
                          v-if="
                            fawkesStore.user?.downgraded &&
                            fawkesStore.user?.at_least_one_pending_document
                          "
                        >
                          <BaseTooltip>
                            <template #activator>
                              <div>
                                <dt class="sr-only">status</dt>
                                <dd
                                  class="flex items-center text-sm font-medium leading-5 text-gray-500 sm:mr-6 sm:mt-0"
                                >
                                  <svg
                                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                  Pièces justificatives en cours de validation
                                </dd>
                              </div>
                            </template>
                            Pour des raisons de sécurité, vous ne pouvez pas
                            débiter votre compte tant que vos pièces ne sont pas
                            validées.
                          </BaseTooltip>
                        </div>
                        <div v-else-if="fawkesStore.user?.downgraded">
                          <BaseTooltip>
                            <template #activator>
                              <dt class="sr-only">Downgraded</dt>
                              <dd
                                class="flex items-center text-xs font-medium leading-5 text-yellow-600 sm:text-sm sm:mr-6"
                              >
                                <svg
                                  width="16"
                                  height="14"
                                  viewBox="0 0 16 14"
                                  fill="none"
                                  class="flex-shrink-0 mr-1.5 h-4 w-4"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.25694 1.09882C7.02154 -0.26048 8.97863 -0.260481 9.74324 1.09882L15.3235 11.0194C16.0735 12.3526 15.11 13.9999 13.5804 13.9999H2.41978C0.890129 13.9999 -0.073299 12.3526 0.676631 11.0194L6.25694 1.09882ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM8 3C7.44772 3 7 3.44772 7 4V7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7V4C9 3.44772 8.55228 3 8 3Z"
                                    fill="#F59E0B"
                                  />
                                </svg>

                                Vos pièces doivent être mises à jour
                              </dd>
                            </template>
                            Pour des raisons de sécurité, vous ne pouvez pas
                            débiter votre compte tant que vos pièces
                            justificatives ne sont pas à jour.
                          </BaseTooltip>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          fawkesStore.user?.filled_in_documents &&
                          !fawkesStore.user?.kyc_validated &&
                          !fawkesStore.user?.downgraded &&
                          fawkesStore.user?.at_least_one_pending_document
                        "
                      >
                        <dt class="sr-only">status</dt>
                        <dd
                          class="flex items-center text-sm font-medium leading-5 text-gray-500 sm:mr-6 sm:mt-0"
                        >
                          <svg
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>

                          Pièces justificatives en cours de validation
                        </dd>
                      </div>
                      <div
                        v-else-if="
                          (fawkesStore.user?.fully_filled_in_profile ||
                            fawkesStore.user?.downgraded) &&
                          fawkesStore.user?.not_compliant
                        "
                      >
                        <dt class="sr-only">status</dt>
                        <dd
                          class="flex items-center text-sm font-medium leading-5 text-gray-500 sm:mr-6 sm:mt-0"
                        >
                          <svg
                            class="flex-shrink-0 mr-1.5 text-orange-400"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM9 12C9 12.2652 8.89464 12.5196 8.70711 12.7071C8.51957 12.8946 8.26522 13 8 13C7.73478 13 7.48043 12.8946 7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929C7.48043 11.1054 7.73478 11 8 11C8.26522 11 8.51957 11.1054 8.70711 11.2929C8.89464 11.4804 9 11.7348 9 12ZM8 3C7.73478 3 7.48043 3.10536 7.29289 3.29289C7.10536 3.48043 7 3.73478 7 4V8C7 8.26522 7.10536 8.51957 7.29289 8.70711C7.48043 8.89464 7.73478 9 8 9C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V4C9 3.73478 8.89464 3.48043 8.70711 3.29289C8.51957 3.10536 8.26522 3 8 3Z"
                              fill="#EF4444"
                            />
                          </svg>
                          Votre profil n’a pas été validé
                        </dd>
                      </div>
                      <div
                        v-else-if="
                          (fawkesStore.user?.fully_filled_in_profile ||
                            fawkesStore.user?.downgraded) &&
                          !fawkesStore.user?.can_access_crowdfunding
                        "
                      >
                        <dt class="sr-only">status</dt>
                        <dd
                          class="flex items-center text-sm font-medium leading-5 text-gray-500 sm:mr-6 sm:mt-0"
                        >
                          <svg
                            class="mr-1.5 text-orange-400"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2 7V5C2 3.67392 2.52678 2.40215 3.46447 1.46447C4.40215 0.526784 5.67392 0 7 0C8.32608 0 9.59785 0.526784 10.5355 1.46447C11.4732 2.40215 12 3.67392 12 5V7C12.5304 7 13.0391 7.21071 13.4142 7.58579C13.7893 7.96086 14 8.46957 14 9V14C14 14.5304 13.7893 15.0391 13.4142 15.4142C13.0391 15.7893 12.5304 16 12 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V9C0 8.46957 0.210714 7.96086 0.585786 7.58579C0.960859 7.21071 1.46957 7 2 7ZM10 5V7H4V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2C7.79565 2 8.55871 2.31607 9.12132 2.87868C9.68393 3.44129 10 4.20435 10 5Z"
                              fill="#FB923C"
                            />
                          </svg>
                          Profil restreint aux SCPI
                        </dd>
                      </div>
                      <div v-else>
                        <dt class="sr-only">status</dt>
                        <dd
                          class="flex items-center text-sm font-medium leading-5 text-gray-500 sm:mr-6 sm:mt-0"
                        >
                          <svg
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-orange-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          Profil à compléter
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex mt-2 space-x-3 md:mt-0 md:ml-4">
              <div class="relative group">
                <component
                  :is="fawkesStore.user?.can_debit ? 'router-link' : 'span'"
                  to="/mon-compte/dashboard/debits/new"
                  class="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-center text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md outline-none hover:text-gray-500 focus:shadow-outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50"
                  :class="
                    fawkesStore.user?.can_debit
                      ? 'cursor-pointer'
                      : 'opacity-50 cursor-not-allowed'
                  "
                >
                  Débiter mon portefeuille
                </component>
                <div
                  v-if="!fawkesStore.user?.can_debit"
                  class="absolute left-0 hidden group-hover:inline-block top-[110%]"
                >
                  <div class="px-4 py-1 text-xs text-white bg-black rounded">
                    Votre profil doit être entièrement validé <br />
                    pour pouvoir débiter votre portefeuille
                  </div>
                </div>
              </div>
              <div class="relative group">
                <span class="rounded-md shadow-sm">
                  <component
                    :is="fawkesStore.user?.can_credit ? 'router-link' : 'span'"
                    to="/mon-compte/dashboard/credits/new"
                    class="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-center text-white transition duration-150 ease-in-out bg-gray-900 border border-transparent rounded-md outline-none hover:bg-gray-800 focus:outline-none focus:shadow-outline-gray focus:border-gray-700 active:bg-gray-700"
                    :class="
                      fawkesStore.user?.can_credit
                        ? 'cursor-pointer'
                        : 'opacity-50 cursor-not-allowed'
                    "
                  >
                    Créditer mon portefeuille
                  </component>
                </span>

                <div
                  v-if="!fawkesStore.user?.can_credit"
                  class="absolute left-0 hidden group-hover:inline-block top-[110%]"
                >
                  <div class="px-4 py-1 text-xs text-white bg-black rounded">
                    Votre profil doit être entièrement validé <br />
                    pour pouvoir créditer votre portefeuille
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main class="relative pb-20 overflow-auto grow">
        <div class="px-6 mx-auto overflow-hidden max-w-7xl lg:px-8">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { BaseTooltip, BaseIcon } from '@/components/atoms'
import { BaseAvatar, StatutWarningCard } from '@/components/molecules'
import { formatCurrency } from '@/logic/useNumbers'
import { useFawkesStore } from '@/stores'

const fawkesStore = useFawkesStore()

// Profile
const profile = ref(null)
const profileOpened = ref(false)
const openProfile = () => {
  profileOpened.value = !profileOpened.value
}
onClickOutside(profile, () => (profileOpened.value ? openProfile() : {}))

//Mobile Sidebar
const sidebar = ref(null)
const sidebarOpened = ref(false)
const openSideBar = () => {
  sidebarOpened.value = !sidebarOpened.value
}
onClickOutside(sidebar, () => (sidebarOpened.value ? openSideBar() : {}))
</script>
