import { IOnboardingStep } from '@/types'

export const netRevenue: IOnboardingStep = {
  id: 'netRevenue',
  fields: [
    {
      id: 'form_details.net_annual_revenue_amount'
    },
    {
      id: 'form_details.liquid_assets_amount'
    },
    {
      id: 'form_details.financial_commitments_amount'
    }
  ]
}

export const confirmation: IOnboardingStep = {
  id: 'exactness',
  isAutoSubmit: true,

  fields: [
    {
      id: 'form_details.has_confirmed_capacity_to_bear_losses',
      component: 'toggle-button',
      componentProps: {
        commentText: `Je déclare avoir pris connaissance des résultats de ma simulation et de ma capacité à supporter les pertes.`,
        isRequired: true
      }
    }
  ]
}

export const ONBOARDING_SIMULATION = [netRevenue, confirmation]
