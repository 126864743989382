import { selectCountryOptions, selectLegalFormsOptions } from '@/logic/useSelect'
import { ICompanyUser, IOnboardingStep, Ok, User } from '@/types'
import { useUserStore, useFormTemplateStore } from '@/stores'
import { storeToRefs } from 'pinia'
import { restructureObject } from '@/logic/useObject'
import { Ternary } from '@/types/input2.interface'
import { ternaryToBooleanOrNull } from '@/logic/useTernary'
import { dateToday, dateMinus110Years } from '../logic/useDates'

export const typeStep: IOnboardingStep = {
  id: 'type',
  title: `Vous êtes ?`,
  description: `Un particulier correspond à une personne physique. Une entreprise correspond à une personne morale. Les auto-entrepreneurs sont considérés comme des personnes physiques.`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.type',
      component: 'buttons',
      componentProps: {
        options: [
          {
            label: 'Un particulier',
            value: 'natural',
            col: 6,
            iconBefore: { name: 'person', ariaLabel: "Icône d'utilisateur" },
            'data-Testid': 'about-type-pp'
          },
          {
            label: 'Une entreprise',
            value: 'legal',
            col: 6,
            iconBefore: { name: 'building', ariaLabel: "Icône d'immeuble" },
            'data-Testid': 'about-type-pm'
          }
        ]
      }
    }
  ]
}

// LEGAL (PM)

export const countryHeadOfficeStep: IOnboardingStep = {
  id: 'country_headoffice',
  title: `Le siège social de votre entreprise se situe ?`,
  displayCondition: (formData) => formData['form_details.type'] === 'legal',
  isAutoSubmit: true,
  fields: [
    {
      id: 'country_headoffice.choice',
      component: 'buttons',
      isVirtual: true,
      default: (user: User) =>
        user.form_details?.company?.address?.country
          ? user.form_details?.company?.address?.country === 'FR'
            ? 'france'
            : 'abroad'
          : null,
      componentProps: {
        options: [
          {
            label: 'En France',
            value: 'france',
            col: 6,
            iconBefore: {
              name: 'flag-fr',
              ariaLabel: 'Icône de drapeau français'
            }
          },
          {
            label: "À l'étranger",
            value: 'abroad',
            col: 6,
            iconBefore: {
              name: 'flag-world',
              ariaLabel: 'Icône de globe terrestre'
            }
          }
        ]
      }
    },
    {
      id: 'country_headoffice.location',
      label: `Pays`,
      component: 'select',
      isVirtual: true,
      displayCondition: (formData) => {
        return (
          formData['country_headoffice.choice'] === 'abroad' &&
          formData['form_details.type'] === 'legal'
        )
      },
      default: (user: User) =>
        user.form_details?.company?.address?.country
          ? user.form_details?.company?.address?.country
          : null,
      componentProps: {
        options: () => {
          const formTemplateStore = useFormTemplateStore()
          const { countries } = storeToRefs(formTemplateStore)

          return selectCountryOptions(countries.value, 'FR')
        }
      }
    }
  ]
}

export const companyNameStep: IOnboardingStep = {
  id: 'company',
  title: `Quelle est votre entreprise ?`,
  displayCondition: (formData) =>
    formData['form_details.type'] === 'legal' &&
    formData['country_headoffice.choice'] !== 'abroad',
  fields: [
    {
      id: 'form_details.company.name',
      component: 'pappers-input',
      label: 'Raison sociale'
    }
  ]
}

export const companyAbroadName: IOnboardingStep = {
  id: 'company_abroad_name',
  title: `Quelle est votre entreprise ?`,
  displayCondition: (formData) =>
    formData['form_details.type'] === 'legal' &&
    formData['country_headoffice.choice'] === 'abroad',
  isAutoSubmit: false,
  fields: [
    {
      id: 'form_details.company.name',
      label: 'Raison sociale'
    }
  ]
}

export const companyAddressStep: IOnboardingStep = {
  id: 'company_address',
  title: `Siège social de l'entreprise`,
  displayCondition: (formData) => formData['form_details.type'] === 'legal',
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.company.address',
      component: 'address'
    },
    {
      id: 'has_company_fiscal_address',
      isVirtual: true,
      isRequired: false,
      component: 'checkbox',
      displayCondition: (formData) => {
        return formData['form_details.company.address']?.address ? true : false
      },
      default: (user: User) =>
        user.form_details?.company?.fiscal_address?.address ? true : false,
      watch: async (formData) => {
        if (formData.has_company_fiscal_address) return new Ok(true)

        const userStore = useUserStore()
        const payload: any = restructureObject(formData)

        return await userStore.edit({
          form_details: {
            company: {
              address: payload.form_details.company.address,
              fiscal_address: null
            }
          }
        })
      },
      label: `L'adresse fiscale est différente de l’adresse du siège social.`
    }
  ]
}

export const companyFiscalAddressStep: IOnboardingStep = {
  id: 'company_fiscal_address',
  title: `Adresse fiscale de l'entreprise`,
  isAutoSubmit: true,
  displayCondition: (formData, serverData) => {
    return (
      serverData.has_company_fiscal_address === true &&
      formData['form_details.type'] === 'legal'
    )
  },
  fields: [
    {
      id: 'form_details.company.fiscal_address',
      component: 'address'
    }
  ]
}

export const companyInfoStep: IOnboardingStep = {
  id: 'info',
  title: `Informations de l'entreprise`,
  displayCondition: (formData) => formData['form_details.type'] === 'legal',
  fields: [
    {
      id: 'form_details.company.siret',
      label: `Votre numéro d'identification`,
      displayCondition: (formData) =>
        formData['country_headoffice.choice'] === 'abroad'
    },
    {
      id: 'form_details.company.siret',
      label: `SIRET`,
      displayCondition: (formData) =>
        formData['country_headoffice.choice'] !== 'abroad'
    },
    {
      id: 'form_details.company.rcs_number',
      label: `Numéro RCS`,
      displayCondition: (formData) =>
        formData['country_headoffice.choice'] !== 'abroad'
    },
    {
      id: 'form_details.company.legal_form',
      label: `Forme juridique de votre entreprise`,
      isRequired: true,
      displayCondition: (formData) =>
        formData['country_headoffice.choice'] !== 'abroad',
      component: 'select',
      componentProps: {
        options: () => {
          const formTemplateStore = useFormTemplateStore()
          const { legal_forms } = storeToRefs(formTemplateStore)
          return selectLegalFormsOptions(legal_forms.value)
        }
      }
    }
  ]
}

export const companyIsLegalRep: IOnboardingStep = {
  id: 'is_representative',
  title: `Vous agissez en qualité de`,
  displayCondition: (formData) => {
    return formData['form_details.type'] === 'legal'
  },
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.is_representative',
      component: 'buttons',
      default: (user: User) => (user.form_details?.first_name ? true : null),
      componentProps: {
        options: [
          {
            label: 'Représentant légal',
            value: true,
            col: 6
          },
          {
            label: 'Autre',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const companyOwnerStep: IOnboardingStep = {
  id: 'owner',
  title: `Qui êtes-vous ?`,
  displayCondition: (formData) => {
    return (
      formData['form_details.type'] === 'legal' &&
      formData['form_details.company.representatives'] &&
      formData['form_details.company.representatives']?.length > 0 &&
      !(
        formData['form_details.first_name'] &&
        formData['form_details.last_name']
      )
    )
  },
  completedCondition: () => {
    return false
  },
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.company.representatives',
      component: 'company-owner'
    }
  ]
}

export const companyHasBeneficiaryStep: IOnboardingStep = {
  id: 'sole_beneficiaries',
  title: `Avez-vous des bénéficiaires effectifs (UBO) à déclarer ?`,
  description: `Toute personne physique qui détient ou contrôle l’ensemble du capital social, ou une partie, de la société sera considérée comme un "bénéficiaire effectif".
  Pour plus de précisions, veuillez consulter l'info bulle ci-contre.`,
  tooltip: `Toute personne physique qui détient ou contrôle l’ensemble du capital social, ou une partie, de la société sera considérée comme un "bénéficiaire effectif.`,
  modal: 'beneficiaries',
  displayCondition: (formData) => {
    return (
      formData['form_details.type'] === 'legal'
    )
  },
  completedCondition: (formData: any) => {
    return typeof formData.company_has_beneficiaries === 'boolean'
  },
  isAutoSubmit: true,
  fields: [
    {
      id: 'company_has_beneficiaries',
      component: 'radio-multiple',
      isVirtual: true,
      default: (user: User) => {
        if (!user.phone_number_validated_at) return null

        return (user.form_details?.company?.beneficial_owners ?? []).length > 0
          ? true
          : false
      },
      watch: async (formData) => {
        const userStore = useUserStore()
        if (formData.company_has_beneficiaries) return new Ok(true)

        return await userStore.edit({
          form_details: {
            company: {
              beneficial_owners: []
            }
          }
        })
      },
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const companyBeneficiariesStep: IOnboardingStep = {
  id: 'beneficiaries',
  title: `Autres bénéficiaires de l'entreprise`,
  displayCondition: (formData) =>
    formData['form_details.type'] === 'legal' &&
    formData.company_has_beneficiaries === true,
  isAutoSubmit: true,
  completedCondition: (formData: any) => {
    const owners: ICompanyUser[] =
      formData['form_details.company.beneficial_owners'] ?? []

    const completedProfiles = owners.filter((owner) => {
      return (
        owner.first_name &&
        owner.last_name &&
        owner.address &&
        owner.birthdate &&
        owner.pep !== null &&
        owner.us_person !== null
      )
    })

    return (owners.length > 0 && completedProfiles.length === owners.length) || formData.company_has_beneficiaries === false
  },
  fields: [
    {
      id: 'form_details.company.beneficial_owners',
      component: 'company-beneficials',
      watch: async (formData) => {
        const userStore = useUserStore()

        const owners = (formData['form_details.company.beneficial_owners'] ??
          []) as unknown as ICompanyUser[]

        if (!owners) return new Ok(true)

        return await userStore.edit({
          form_details: {
            company: {
              beneficial_owners: owners.map((owner) => ({
                ...owner,
                pep: ternaryToBooleanOrNull(owner.pep),
                us_person: ternaryToBooleanOrNull(owner.us_person)
              }))
            }
          }
        })
      }
    }
  ]
}

// NATURAL (PP)

export const identityStep: IOnboardingStep = {
  id: 'identity',
  title: `Votre identité`,
  displayCondition: (_formData) => {
    return true
  },
  fields: [
    {
      id: 'form_details.civility',
      component: 'radio-multiple',
      default: '',
      componentProps: {
        options: [
          {
            label: `Monsieur`,
            value: 'mr',
            col: 6,
            dataTestid: 'about-civility-mr'
          },
          {
            label: `Madame`,
            value: 'ms',
            col: 6,
            dataTestid: 'about-civility-mrs'
          }
        ]
      }
    },
    {
      id: 'form_details.first_name',
      label: `Prénom`,
      componentProps: {
        dataTestid: 'about-firstname'
      }
    },
    {
      id: 'form_details.last_name',
      label: `Nom`,
      componentProps: {
        dataTestid: 'about-lastname'
      }
    },
    {
      id: 'form_details.birth_name',
      label: `Nom de naissance (facultatif)`,
      isRequired: false,
      default: '',
      componentProps: {
        dataTestid: 'about-birthname'
      }
    }
  ]
}

export const personalStep: IOnboardingStep = {
  id: 'personal',
  title: `Vos informations personnelles`,
  description: `Renseignez les informations présentes sur votre pièce d’identité`,
  displayCondition: (_formData) => {
    return true
  },
  fields: [
    {
      id: 'form_details.birthdate',
      label: `Date de naissance`,
      col: 6,
      validation: [{ id: 'not-minor' }],
      componentProps: {
        type: 'date',
        dataTestid: 'about-birthdate',
        min: dateMinus110Years,
        max: dateToday
      }
    },
    {
      id: 'form_details.nationality',
      label: `Nationalité`,
      col: 6,
      component: 'select',
      componentProps: {
        options: () => {
          const formTemplateStore = useFormTemplateStore()
          const { countries } = storeToRefs(formTemplateStore)

          return selectCountryOptions(countries.value)
        },
        dataTestid: 'about-nationality'
      }
    },
    {
      id: 'form_details.birth_zip_code',
      label: `Code postal de naissance`,
      col: 6,
      componentProps: {
        dataTestid: 'about-birthzipcode'
      }
    },
    {
      id: 'form_details.birth_city',
      label: `Commune de naissance`,
      col: 6,
      componentProps: {
        dataTestid: 'about-birthcity'
      }
    },
    {
      id: 'form_details.birth_country',
      component: 'select',
      label: 'Pays de naissance',
      componentProps: {
        options: () => {
          const formTemplateStore = useFormTemplateStore()
          const { countries } = storeToRefs(formTemplateStore)
          return selectCountryOptions(countries.value)
        },
        dataTestid: 'about-birthcountry'
      }
    }
  ]
}

export const addressStep: IOnboardingStep = {
  id: 'address',
  title: `Vos coordonnées`,
  isAutoSubmit: true,
  displayCondition: (_formData) => {
    return true
  },
  fields: [
    {
      id: 'form_details.address',
      component: 'address'
    },
    {
      id: 'has_fiscal_address',
      isVirtual: true,
      isRequired: false,
      component: 'checkbox',
      displayCondition: (formData) => {
        return formData['form_details.address']?.address ? true : false
      },
      default: (user: User) =>
        user.form_details?.fiscal_address?.address ? true : false,
      watch: async (formData) => {
        if (formData.has_fiscal_address) return new Ok(true)

        const userStore = useUserStore()
        const payload: any = restructureObject(formData)

        return await userStore.edit({
          form_details: {
            address: payload.form_details.address,
            fiscal_address: null
          }
        })
      },
      label: `Mon adresse fiscale est différente de mon adresse postale.`,
      componentProps: {
        dataTestid: 'about-hadfiscaladdressdiff'
      }
    }
  ]
}

export const fiscalAddressStep: IOnboardingStep = {
  id: 'fiscal_address',
  title: `Votre adresse fiscale`,
  isAutoSubmit: true,
  displayCondition: (_formData, serverData) => {
    return serverData.has_fiscal_address === true
  },
  fields: [
    {
      id: 'form_details.fiscal_address',
      component: 'address'
    }
  ]
}

export const pepStep: IOnboardingStep = {
  id: 'pep',
  title: `Êtes-vous une personne politiquement exposée ?`,
  tooltip: `Les personnes politiquement exposées (PPE) sont des personnes qui sont considérées, au niveau international, comme exposées à des "risques plus élevés" de blanchiment de capitaux.`,
  modal: 'pep',
  displayCondition: (_formData) => {
    return true
  },
  fields: [
    {
      id: 'form_details.pep',
      component: 'radio-multiple',
      formatters: ['as-ternary'],
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: Ternary.true,
            col: 6,
            alert: {
              content:
                "<p>Vous venez de vous déclarer en tant que PPE (Personne politiquement exposée).</p><p>Dans le cadre de la Lutte Contre le Blanchiment des Capitaux et le Financement du Terrorisme (LCB/FT), nous vous demandons de télécharger <a class='alert-content' href='https://mangopay.com/terms/PEP/PEP_form_FR.pdf' target='_blank'>le document de déclaration relative aux PPE</a> fourni par notre prestataire de paiement.</p><p>Pour investir sur notre plateforme, merci de nous le transmettre complété, daté et signé à l’adresse <a class='alert-content' href='mailto:investisseurs@fundimmo.com'>investisseurs@fundimmo.com.</a></p>",
              timer: 80000,
              type: 'default',
              color: 'blue'
            },
            dataTestid: 'about-pep-yes'
          },
          {
            label: 'Non',
            value: Ternary.false,
            col: 6,
            dataTestid: 'about-pep-no'
          }
        ]
      }
    },
    {
      id: 'form_details.pep_description',
      label: 'Précisez...',
      isRequired: false,
      displayCondition: (formData) =>
        formData['form_details.pep'] === Ternary.true,
      componentProps: {
        dataTestid: 'about-ppe-precision'
      }
    }
  ]
}

export const usStep: IOnboardingStep = {
  id: 'us_person',
  title: `Êtes-vous une US person ?`,
  displayCondition: (_formData) => {
    return true
  },
  tooltip: `US person » au sens de la réglementation dite FATCA (Foreign Account Tax Compliance Act) applicable depuis le 1er juillet 2014.`,
  modal: 'us',
  fields: [
    {
      id: 'form_details.us_person',
      component: 'radio-multiple',
      formatters: ['as-ternary'],
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: Ternary.true,
            col: 6,
            dataTestid: 'about-us-yes'
          },
          {
            label: 'Non',
            value: Ternary.false,
            col: 6,
            dataTestid: 'about-us-no'
          }
        ]
      }
    }
  ]
}

export const phoneStep: IOnboardingStep = {
  id: 'phone',
  title: `Votre téléphone`,
  description: `Protégez votre compte en ajoutant votre numéro de téléphone.`,
  isAutoSubmit: true,
  displayCondition: (_formData) => {
    return true
  },
  fields: [
    {
      id: 'phone_number',
      isVirtual: true,
      default: (user: User) => {
        return user.phone_number_validated_at ? user.phone_number : ''
      },
      component: 'phone-code',
      componentProps: {
        isRequired: true,
        dataTestid: 'about-phone'
      }
    }
  ]
}

export const ONBOARDING_ABOUT_STEPS = [
  typeStep,

  countryHeadOfficeStep,

  companyNameStep,
  companyAbroadName,

  companyInfoStep,
  companyAddressStep,
  companyFiscalAddressStep,

  companyIsLegalRep,
  companyOwnerStep,

  identityStep,
  personalStep,
  addressStep,
  fiscalAddressStep,

  pepStep,
  usStep,

  companyHasBeneficiaryStep,
  companyBeneficiariesStep,

  phoneStep
]
