import { Movement, TableDataValue, Result, Ok } from '@/types'
import { fetchApi } from '@/logic/useApi'

export const fetchMovements = async (
  params?: string[][]
): Promise<Result<{ data: Movement[]; total: number }>> => {
  const response = await fetchApi('movements', params)
  if (!response.ok) return response

  const movements = (response.data as TableDataValue[]).map(
    (d) => new Movement(d as unknown as Movement)
  )
  return new Ok({
    data: movements,
    total: Number(response.headers.get('x-total-count'))
  })
}
