import { defineStore } from 'pinia'
import { fetchFawkesUser } from '@/api/fawkes/authApi'
import { FawkesUser, Ok } from '@/types'
import { ref } from 'vue'

export const useFawkesStore = defineStore('fawkes', () => {
  const user = ref<FawkesUser | null>(null)
  const isAuth = ref(localStorage.getItem('isAuth') ?? '')
  const status = ref<boolean>(false)

  const fetchUser = async () => {
    const response = await fetchFawkesUser()
    if (!response.ok) return response
    user.value = response.data.user
    return new Ok({
      user: response
    })
  }
  return {
    user,
    isAuth,
    status,
    fetchUser
  }
})
