import { authCheckAPI } from '@/api/authApi'
import { useUserStore } from '@/stores/user'

export default async () => {
  await authCheckAPI()

  const userStore = useUserStore()
  const { user } = userStore
  if (user?.email_validated_at && !user?.acceptedCGU) {
    return { name: 'auth-register-cgu' }
  }
  else if (user?.email_validated_at && user.acceptedCGU) {
    return { name: 'onboarding' }
  }

  return true
}
