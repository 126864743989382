import { IOnboardingStep } from '@/types'
import { useFormTemplateStore } from '@/stores'
import { storeToRefs } from 'pinia'
import {
  selectDegreesOptions,
  selectProfessionsOptions
} from '@/logic/useSelect'

export const profession: IOnboardingStep = {
  id: 'profession',
  title: 'Quelle est votre profession ?',

  fields: [
    {
      id: 'form_details.professional_sector_of_activity',
      label: `Votre groupe socioprofessionnel`,
      component: 'select',
      componentProps: {
        options: () => {
          const formTemplateStore = useFormTemplateStore()
          const { professions_classification_groups } =
            storeToRefs(formTemplateStore)

          return selectProfessionsOptions(
            professions_classification_groups.value
          )
        }
      }
    },
    {
      id: 'form_details.professional_title',
      label: `Votre profession`,
      isRequired: false,
      displayCondition: (formData) => {
        return formData['form_details.professional_sector_of_activity'] !==
          'retirees' &&
          formData['form_details.professional_sector_of_activity'] !==
            'other_non_workers'
          ? true
          : false
      }
    }
  ]
}

export const diploma: IOnboardingStep = {
  id: 'diploma',
  title: 'Quel est votre plus haut diplôme obtenu ?',

  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.highest_degree',
      component: 'select',
      label: 'Votre diplôme',
      componentProps: {
        options: () => {
          const formTemplateStore = useFormTemplateStore()
          const { degrees } = storeToRefs(formTemplateStore)

          return selectDegreesOptions(degrees.value)
        }
      }
    }
  ]
}

export const isFinancialSector: IOnboardingStep = {
  id: 'isFinancialSector',
  title:
    'Exercez-vous ou avez-vous déjà exercé une fonction dans le secteur financier et/ou financement participatif pendant plus de deux ans ?',

  displayCondition: (formData, serverData, userData) => {
    return (
      serverData['form_details.highest_degree'] !== '' &&
      !userData?.form_details.is_sophisticated
    )
  },
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.has_worked_in_financial_sector',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const doFinancialOperations: IOnboardingStep = {
  id: 'doFinancialOperations',
  title: 'Avez-vous déjà réalisé des opérations sur des produits financiers ?',

  isAutoSubmit: true,
  displayCondition: (formData, serverData, userData) => {
    return !userData?.form_details.is_sophisticated
  },
  fields: [
    {
      id: 'form_details.has_done_transactions_on_financial_products',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const lastOperationPeriod: IOnboardingStep = {
  id: 'lastOperationPeriod',
  title: `Depuis combien de temps ?`,

  displayCondition: (formData, serverData) => {
    return (
      serverData['form_details.has_done_transactions_on_financial_products'] ===
      true
    )
  },
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.last_transactions_period_on_financial_products',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Moins de 2 ans',
            value: 'under_2_years'
          },
          {
            label: 'Entre 2 et 5 ans',
            value: 'between_2_and_5_years'
          },
          {
            label: 'Entre 5 et 10 ans',
            value: 'between_5_and_10_years'
          },
          {
            label: 'Plus de 10 ans',
            value: 'over_10_years'
          }
        ]
      }
    }
  ]
}

export const lastOperationType: IOnboardingStep = {
  id: 'lastOperationType',
  title: `Dans quels produits financiers avez-vous déjà investi ?`,

  displayCondition: (formData, serverData) => {
    return (
      serverData['form_details.has_done_transactions_on_financial_products'] ===
      true
    )
  },
  fields: [
    {
      id: 'form_details.last_transactions_types_on_financial_products',
      label: `Produits financiers`,
      component: 'checkbox-multiple',
      validation: [{ id: 'array-length', params: { min: 1 } }],
      componentProps: {
        name: 'duration',
        theme: 'card',
        labelPosition: 'left',
        color: 'blue',
        options: [
          {
            label: 'Actions cotées',
            value: 'listed_shares'
          },
          {
            label: 'Actions non cotés',
            value: 'unlisted_shares'
          },
          {
            label: 'Obligations cotées',
            value: 'listed_bonds'
          },
          {
            label: 'Obligations non cotées',
            value: 'unlisted_bonds'
          },
          {
            label: `Titre non cotées via un fonds d'investissement (ex: FCPR, FCPI, FIP)`,
            value: 'unlisted_securities_via_investment_fund'
          },
          {
            label: 'Parts de sociétés civiles immobilières (SCI)',
            value: 'shares_in_non_trading_property_companies'
          },
          {
            label:
              'Produits dérivés et complexes (options, certificats, warrants)',
            value: 'derivatives_and_complex_products'
          },
          {
            label: 'OPCVM (SICAV, FCP, ...)',
            value: 'ucits'
          },
          {
            label: 'Autre (Assurance vie, Comptes sur livret ...)',
            value: 'other'
          }
        ]
      }
    }
  ]
}

export const lastYearQuantity: IOnboardingStep = {
  id: 'lastYearQuantity',
  title: `Combien de transactions sur les produits financiers avez-vous réalisées au cours de l'année passée ?`,

  displayCondition: (formData, serverData) => {
    return (
      serverData['form_details.has_done_transactions_on_financial_products'] ===
      true
    )
  },
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.last_transactions_quantity_on_financial_products',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Aucune',
            value: 'none'
          },
          {
            label: "Moins d'une par mois",
            value: 'less_than_1_per_month'
          },
          {
            label: 'Entre 1 et 5 par mois',
            value: 'between_1_and_5_per_month'
          },
          {
            label: 'Plus de 5 par mois',
            value: 'more_than_5_per_month'
          }
        ]
      }
    }
  ]
}

export const crowdfundingExperience: IOnboardingStep = {
  id: 'crowdfundingExperience',
  title:
    'Avez-vous des connaissances ou des expérience passées dans le domaine du financement participatif ?',

  isAutoSubmit: true,
  displayCondition: (formData, serverData, userData) => {
    return !userData?.form_details.is_sophisticated
  },
  fields: [
    {
      id: 'form_details.has_crowdfunding_experiences',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const ONBOARDING_EXPERIENCE_STEPS = [
  profession,
  diploma,
  isFinancialSector,
  doFinancialOperations,
  lastOperationPeriod,
  lastOperationType,
  lastYearQuantity,
  crowdfundingExperience
]
