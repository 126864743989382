<template>
  <div
    class="flex items-center gap-6 px-6 py-4 md:px-8 md:py-6 border-b-[3px] md:border-x-[3px] md:border-t-[3px] md:rounded-2xl overflow-hidden"
    :class="baseAlertCardClasses(props)"
  >
    <BaseIcon
      :name="
        messageType === 'bug' ? 'bug' : (baseAlertCardIconName(props) as string)
      "
      :width="29"
      :class="messageType === 'bug' ? 'text-slate-800' : ''"
    />

    <div
      class="flex-1 text-sm alert-content"
      :class="messageType === 'bug' ? 'text-slate-800' : 'text-slate-500'"
      v-html="content"
    ></div>
    <button class="flex items-center">
      <BaseIcon
        name="cross"
        :width="26"
        class="text-slate-800"
        @click="emit('close')"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { BaseIcon } from '@/components/atoms'
import {
  baseAlertCardClasses,
  baseAlertCardIconName
} from './BaseAlertCardClasses'

const props = withDefaults(
  defineProps<{
    content: string
    messageType?: 'default' | 'bug'
    color: 'red' | 'green' | 'yellow' | 'blue'
  }>(),
  {
    messageType: 'default'
  }
)

const emit = defineEmits(['close'])
</script>
