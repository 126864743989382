import { Project, Result, Ok } from '@/types'
import { fetchApi } from '@/logic/useApi'

export const getProjects = async (
  params?: string[][]
): Promise<Result<{ data: Project[]; total: number }>> => {
  const response = await fetchApi('projects', params)
  if (!response.ok) return response

  const projects = (response.data as Array<Project>).map((d) => new Project(d))

  return new Ok({
    data: projects,
    total: Number(response.headers.get('x-total-count'))
  })
}
