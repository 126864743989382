import { useUserStore } from '@/stores/user'
import { RouteLocationNormalized } from 'vue-router'

export default ({
  to
}: {
  to: RouteLocationNormalized
}) => {
  const userStore = useUserStore()

  const { registerToken, isCodevalidated } = userStore

  if (!registerToken) return { name: 'auth-register' }

  if (isCodevalidated && to.name === 'auth-register-code') {
    return { name: 'auth-register' }
  }

  return  true
}
