import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { Ok, Contribution } from '@/types'

import { fetchContributions } from '@/api/fawkes/contributionApi'

export const useContributionsStore = defineStore('contributions', () => {
  const items: Ref<Contribution[]> = ref([])

  const fetch = async () => {
    const response = await fetchContributions({
      _expand: 'project'
    } as unknown as string[][])
    if (!response.ok) return response

    const { data: contributions } = response.data

    items.value = contributions

    return new Ok({ contributions })
  }

  return {
    fetch,
    items
  }
})
