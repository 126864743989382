import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Alert, Err } from '@/types'

export const useAlertsStore = defineStore('alerts', () => {
  const alerts = ref<Alert[]>([])

  const removeAlert = (alert: Alert) => {
    if (alerts.value) {
      const indicator = alerts.value.indexOf(alert)
      if (indicator > -1) {
        alerts.value.splice(indicator, 1)
      }
    }
  }

  const addAlert = (alert: Alert, timer = 10000) => {
    const found = alerts.value.some((el) => el.id === alert.id)
    if (!found) {
      alerts.value.push(alert)
    }
    setTimeout(() => (removeAlert(alert)), timer)
  }

  const addError = (response: Err) => {
    if (response.error === 'api-error') {
      addAlert({
        id: 'api-error',
        content: response.message,
        color: 'yellow',
        messageType: 'bug'
      })
      response.type = "alert"
      return response
    }
    return response
  }

  return {
    alerts,
    addAlert,
    addError,
    removeAlert
  }
})
