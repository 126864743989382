<template>
  <div
    class="overflow-hidden duration-200 ease-in-out rounded-full tansition"
    :class="baseAvatarClasses(props)"
  >
    <img
      v-if="src"
      class="object-cover bg-indigo-300 bg-auto"
      :src="src"
      :alt="alt"
    />
    <span v-else class="flex items-center justify-center h-full">
      <span class="font-medium leading-non">
        {{ getInitials(firstName, lastName) }}</span
      >
    </span>
  </div>
</template>

<script setup lang="ts">
import { baseAvatarClasses } from './BaseAvatarClasses'

interface BaseAvatarProps {
  firstName?: string
  lastName?: string
  src?: string
  alt?: string
  size?: 'small' | 'medium' | 'large'
  color?: 'gray' | 'lightGray'
}

const props = withDefaults(defineProps<BaseAvatarProps>(), {
  alt: '',
  firstName: '',
  lastName: '',
  src: '',
  size: 'medium',
  color: 'gray'
})

const getInitials = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  return `${firstName?.charAt(0)}.${lastName?.charAt(0)}`.toUpperCase()
}
</script>
