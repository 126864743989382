<template>
  <main class="flex min-h-full p-0 bg-gray-100 lg:bg-white">

    <div
      v-if="alertsStore.alerts"
      class="fixed z-20 flex justify-center w-full mx-auto overflow-hidden top-24"
    >
      <ListAlertCard :alert-list="alertsStore.alerts" @close="(alert) => removeAlert(alert)"/>
    </div>
    <div
      class="inline-flex flex-col justify-between min-h-full m-0 bg-white rounded-none md:m-8 lg:m-0 lg:flex sm:rounded-3xl md:shrink-0 md:w-7/12 grow lg:rounded-none"
      :class="steps[0]"
    >
      <div
        class="flex items-center justify-between px-8 pt-8 md:pt-12 md:px-12"
      >
        <BaseIcon
          :name="logoType"
          :width="logoSize"
          class="duration-500 text-primary-600"
          :class="[isActive ? '' : 'opacity-0 -translate-y-2']"
        />

        <InlineButton
          v-if="
            route.name &&
            (route.name as string).includes('register') &&
            route.name !== 'auth-register-cgu' &&
            route.name !== 'auth-register-confirmed'
          "
          is-underline
          @click="onConnect"
        >
          Se connecter
        </InlineButton>
        <InlineButton
          v-else-if="route.name && (route.name as string).includes('register')"
          is-underline
          @click="onConnect"
        >
          Se déconnecter
        </InlineButton>
      </div>

      <RouterView v-slot="{ Component }">
        <Transition name="transition-onboarding-left" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>

      <div class="h-20" />
    </div>

    <div
      class="sticky top-0 w-full py-0 pb-10 pr-0 lg:pr-6 lg:py-10 lg:h-screen lg:w-5/12 lg:flex shrink-0"
      :class="steps[1]"
    >
      <BaseIcon
        :name="logoType"
        :width="rightLogoSectionWidth"
        class="absolute z-10 duration-500 text-primary-600 top-8 left-8 right-8 md:top-20 md:left-20 md:right-20 lg:hidden"
        :class="[isActive ? '' : 'opacity-0 -translate-y-2']"
      />
      <div
        class="flex flex-col items-center justify-between h-full p-2 overflow-hidden bg-gray-100 lg:p-10 lg:rounded-3xl grow"
        style="perspective: 20000px"
      >
        <template v-if="currentRoute === 'auth'">
          <div />

          <img
            src="@/assets/images/bg/dashboard.jpg"
            class="absolute w-[150%] min-w-[800px] left-[10%] top-[40%] max-w-none rounded-3xl origin-top-left z-20"
            :class="[
              isActive ? 'ease-out duration-700' : 'ease-in duration-500'
            ]"
            :style="{
              transform: isActive
                ? `rotateX(45deg) rotateY(0deg) rotateZ(-45deg)`
                : `rotateX(45deg) rotateY(0deg) rotateZ(-45deg) translate3d(1000px, -0px, 0)`,
              boxShadow: '-25px 25px 25px 0 rgb(0 0 0 / 15%)'
            }"
          />

          <img
            src="@/assets/images/bg/project-1.png"
            class="absolute w-[32%] min-w-[180px] left-[10%] top-[55%] max-w-none rounded-xl origin-top-left z-20"
            :class="[
              isActive ? 'ease-out duration-700' : 'ease-in duration-500'
            ]"
            :style="{
              transform: isActive
                ? `rotateX(45deg) rotateY(0deg) rotateZ(-45deg)`
                : `rotateX(45deg) rotateY(0deg) rotateZ(-45deg) translate3d(-1000px, 0px, 0)`,
              boxShadow: '-25px 25px 25px 0 rgb(0 0 0 / 15%)'
            }"
          />

          <div
            class="relative w-full max-w-xs overflow-hidden duration-500 delay-500 sm:max-w-lg"
            :class="[isActive ? '' : 'opacity-0 translate-y-1']"
          >
            <div class="flex opacity-50 animation-carousel w-max">
              <div
                v-for="i in 2"
                :key="i"
                class="flex items-center px-5 space-x-10 shrink-0"
              >
                <img
                  src="@/assets/images/logos/business-immo.png"
                  alt="Vu sur Business Immo"
                  class="h-6"
                />
                <img
                  src="@/assets/images/logos/le-figaro.png"
                  alt="Vu sur Le Figaro"
                  class="h-6"
                />
                <img
                  src="@/assets/images/logos/les-echos.png"
                  alt="Vu sur Les Echos"
                  class="h-6"
                />
                <img
                  src="@/assets/images/logos/tf1.png"
                  alt="Vu sur TF1"
                  class="h-6"
                />
              </div>

              <div
                class="absolute top-0 left-0 z-10 w-1/12 h-full from-gray-100 to-gray-100/0 bg-gradient-to-r"
              />
              <div
                class="absolute top-0 right-0 z-10 w-1/12 h-full from-gray-100 to-gray-100/0 bg-gradient-to-l"
              />
            </div>
          </div>
        </template>
        <template v-else-if="currentRoute === 'register'">
          <div
            class="flex flex-col justify-center mx-4 leading-6 margin-x-auto grow"
          >
            <BaseTitle level="h4" color="primary" class="text-[32px] mb-6"
              >Investissez dans des opérations immobilières
              <span class="text-blue-500">exclusives</span></BaseTitle
            >
            <p class="mb-6 font-semibold text-gray-700">
              Avec Fundimmo, des dizaines de milliers d’investisseurs financent
              des centaines d’opérations immobilières par an et obtiennent une
              rentabilité attractive à court terme
            </p>
            <ul class="space-y-6 text-gray-700">
              <li class="flex items-center">
                <BaseIcon name="check" class="mr-2 text-green-500" />
                <p>Diversifiez en investissant dès&nbsp;<b>1000€ par projet</b></p>
              </li>
              <li class="flex items-center">
                <BaseIcon name="check" class="mr-2 text-green-500" />
                <p>Des opérations&nbsp;<b>rigoureusement sélectionnées</b></p>
              </li>
              <li class="flex items-center">
                <BaseIcon name="check" class="mr-2 text-green-500" />
                <p>Des durées comprises entre&nbsp;<b>6 et 36 mois</b></p>
              </li>
              <li class="flex items-center">
                <BaseIcon name="check" class="mr-2 text-green-500" />
                <p>Des typologies de projet très&nbsp;<b>variées</b></p>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div class="relative h-full overflow-hidden duration-500 delay-500">
            <div
              class="absolute top-0 left-0 z-10 w-full h-[50px] from-gray-100 to-gray-100/0 bg-gradient-to-b"
            />
            <div class="animation-project-diorama">
              <img
                src="@/assets/images/bg/diorama-loop.webp"
                alt="Images de projets Fundimmo"
              />

              <img
                src="@/assets/images/bg/diorama-loop.webp"
                alt="Images de projets Fundimmo"
              />
            </div>
            <div
              class="absolute bottom-0 right-0 z-10 w-full h-[50px] from-gray-100 to-gray-100/0 bg-gradient-to-t"
            />
          </div>
        </template>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { BaseTitle, InlineButton, BaseIcon } from '@/components/atoms'
import { ListAlertCard } from '@/components/organisms'
import { useUserStore, useAlertsStore } from '@/stores'
import { getScreenFromArray, getCurrentScreen } from '@/logic/useResponsive'

//Alert
const alertsStore = useAlertsStore()
const { removeAlert } = alertsStore

const isActive = ref(false)
const steps = ref(['hidden', '', ''])
const route = useRoute()
const router = useRouter()
const userStore = useUserStore()

const { result: logoType } = getScreenFromArray([
  'fundimmo-logo-icon',
  'fundimmo-logo-icon',
  'fundimmo-logo'
])
const { result: logoSize } = getScreenFromArray([40, 40, 160])
const { result: rightLogoSectionWidth } = getScreenFromArray([40, 40, 160])

const onConnect = () => {
  if (userStore.user) userStore.logOut()

  router.push({ name: 'auth' })
}

const screenId = computed(() => getCurrentScreen().value.id)
const timeout = ref()

watch(screenId, () => {
  if (timeout.value) clearTimeout(timeout.value)

  const sizeScreen = ['xs', 'sm', 'md']

  setTimeout(() => {
    isActive.value = true
  }, 200)

  if (sizeScreen.includes(screenId.value)) {
    timeout.value = setTimeout(() => {
      steps.value = [
        'hidden opacity-0',
        ' invisible opacity-0 transition-opacity duration-500 ease-linear'
      ]

      setTimeout(() => {
        steps.value = ['', 'hidden']
      }, 400)
    }, 1600)
  }
}, { immediate: true })

const currentRoute = computed(() => {
  if (route.name === 'auth') {
    return 'auth'
  } else if (route.name === 'auth-register') {
    return 'register'
  }
  return 'others'
})
</script>
