import { createRouter, createWebHistory, NavigationGuardNext } from 'vue-router'
import { applyMiddlewares } from '@/logic/useRouter'
import isNewLoginEnabled from '@/middleware/isNewLoginEnabled'
import redirectToStep from '@/middleware/redirectToStep'
import isLogged from '@/middleware/isLogged'
import redirectToOnboarding from '@/middleware/redirectToOnboarding'
import redirectToRegister from '@/middleware/redirectToRegister'

const routes = [
  // AUTH
  {
    path: '/',
    meta: {
      layout: 'auth',
      middlewares: [isNewLoginEnabled]
    },
    component: () => import('@/pages/Auth/index.vue'),
    children: [
      {
        name: 'auth',
        path: '',
        meta: {
          middlewares: [redirectToOnboarding]
        },
        component: () => import('@/pages/Auth/AuthLogin.vue')
      },
      {
        path: 'register',
        component: () => import('@/pages/Auth/AuthRegister.vue'),
        children: [
          {
            name: 'auth-register',
            path: '',
            meta: {
              middlewares: [redirectToOnboarding]
            },
            component: () =>
              import('@/pages/Auth/Register/AuthRegisterEmail.vue')
          },
          {
            name: 'auth-register-code',
            path: 'code',
            meta: {
              middlewares: [redirectToRegister]
            },
            component: () =>
              import('@/pages/Auth/Register/AuthRegisterCode.vue')
          },
          {
            name: 'auth-register-password',
            path: 'password',
            meta: {
              middlewares: [redirectToRegister]
            },
            component: () =>
              import('@/pages/Auth/Register/AuthRegisterPassword.vue')
          },
          {
            name: 'auth-register-cgu',
            path: 'cgu',
            meta: {
              middlewares: [isLogged]
            },
            component: () => import('@/pages/Auth/Register/AuthRegisterCGU.vue')
          },
          {
            name: 'auth-register-confirmed',
            path: 'confirmed',
            meta: {
              middlewares: [isLogged]
            },
            component: () =>
              import('@/pages/Auth/Register/AuthRegisterConfirmed.vue')
          }
        ]
      },
      {
        name: 'auth-reset-request',
        path: 'reset-request',
        component: () => import('@/pages/Auth/AuthResetPasswordRequest.vue')
      },
      {
        name: 'auth-reset-password',
        path: 'reset-password',
        component: () => import('@/pages/Auth/AuthResetPasswordConfirm.vue')
      }
    ]
  },

  // ONBOARDING
  {
    name: 'onboarding',
    meta: {
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding',
    component: () => import('@/pages/Onboarding/index.vue')
  },
  {
    name: 'onboarding-home',
    meta: {
      layout: 'default',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/home',
    component: () => import('@/pages/Onboarding/OnboardingHome.vue')
  },
  {
    name: 'onboarding-about',
    path: '/onboarding/about',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    component: () => import('@/pages/Onboarding/OnboardingAbout.vue')
  },

  // Profile Qualification
  {
    name: 'onboarding-knowledgeable-start',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/knowledgeable-start',
    component: () =>
      import('@/pages/Onboarding/OnboardingKnowledgeableStart.vue')
  },
  {
    name: 'onboarding-knowledgeable-questions',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/knowledgeable-questions',
    component: () =>
      import('@/pages/Onboarding/OnboardingKnowledgeableQuestions.vue')
  },
  {
    name: 'onboarding-knowledgeable-validation',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/knowledgeable-validation',
    component: () =>
      import('@/pages/Onboarding/OnboardingKnowledgeableValidation.vue')
  },

  //Other steps
  {
    name: 'onboarding-investments',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/investments',
    component: () => import('@/pages/Onboarding/OnboardingInvestments.vue')
  },
  {
    name: 'onboarding-experience-knowledge',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/experience-knowledge',
    component: () =>
      import('@/pages/Onboarding/OnboardingExperienceKnowledge.vue')
  },
  {
    name: 'onboarding-risks',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/risks',
    component: () => import('@/pages/Onboarding/OnboardingRisks.vue')
  },
  {
    name: 'onboarding-financial-situation',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/financial-situation',
    component: () =>
      import('@/pages/Onboarding/OnboardingFinancialSituation.vue')
  },
  {
    name: 'onboarding-simulation',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/simulation',
    component: () => import('@/pages/Onboarding/OnboardingSimulation.vue')
  },
  {
    name: 'onboarding-documents',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/documents',
    component: () => import('@/pages/Onboarding/OnboardingDocuments.vue')
  },
  {
    name: 'onboarding-confirmation',
    meta: {
      layout: 'onboarding',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/confirmation',
    component: () => import('@/pages/Onboarding/OnboardingConfirmation.vue')
  },
  {
    name: 'onboarding-pending',
    meta: {
      layout: 'error',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/pending',
    component: () => import('@/pages/Onboarding/OnboardingPending.vue')
  },
  {
    name: 'onboarding-end',
    meta: {
      layout: 'error',
      middlewares: [isNewLoginEnabled, isLogged, redirectToStep]
    },
    path: '/onboarding/end',
    component: () => import('@/pages/Onboarding/OnboardingEnd.vue')
  },

  // DASHBOARD
  {
    path: '/dashboard',
    meta: { layout: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: '',
        component: () => import('@/pages/Home.vue')
      },
      {
        name: 'advice',
        path: 'conseil',
        component: () => import('@/pages/Conseil.vue')
      },
      {
        name: 'faq',
        path: 'faq_contact',
        component: () => import('@/pages/Faq.vue')
      },
      {
        name: 'transactions',
        path: 'transactions',
        component: () => import('@/pages/Transactions/index.vue'),
        children: [
          {
            name: 'transactionHistory',
            path: '',
            component: () =>
              import('@/pages/Transactions/TransactionHistory.vue')
          },
          {
            name: 'repayments',
            path: 'in-progress',
            component: () => import('@/pages/Transactions/Repayments.vue')
          },
          {
            name: 'fiscals',
            path: 'in-progress',
            component: () => import('@/pages/Transactions/Repayments.vue')
          }
        ]
      },
      {
        name: 'subscriptions',
        path: 'subscriptions',
        component: () => import('@/pages/Subscriptions/index.vue'),
        children: [
          {
            name: 'subscriptionsSummary',
            path: '',
            component: () =>
              import('@/pages/Subscriptions/SubscriptionsSummary.vue')
          },
          {
            name: 'subscriptionsInProgress',
            path: 'in-progress',
            component: () =>
              import('@/pages/Subscriptions/SubscriptionsInProgress.vue')
          }
        ]
      },
      {
        name: 'updates',
        path: 'user_posts',
        component: () => import('@/pages/Posts/index.vue'),
        children: [
          {
            name: 'postsByProject',
            path: '',
            component: () => import('@/pages/Posts/PostsByProject.vue')
          },
          {
            name: 'postsAll',
            path: 'all',
            component: () => import('@/pages/Posts/PostsAll.vue')
          }
        ]
      }
    ]
  },

  // LOGOUT
  {
    name: 'logout',
    path: '/logout',
    component: () => import('@/pages/Auth/AuthLogout.vue')
  },
  // CONTACT
  {
    name: 'contact',
    path: '/contact',
    meta: {
      layout: 'default'
    },
    component: () => import('@/pages/Contact.vue')
  },
  // ERRORS
  {
    name: 'error',
    path: '/error',
    meta: {
      layout: 'error',
      middlewares: [redirectToStep]
    },
    component: () => import('@/pages/Error.vue')
  },

  // Test
  {
    name: 'test',
    path: '/test',
    meta: {
      layout: 'onboarding'
    },
    component: () => import('@/pages/Test.vue'),
    beforeEnter: (next: NavigationGuardNext) => {
      if (import.meta.env.MODE === 'development') {
        next()
      }
      return false
    }
  },

  {
    path: '/:pathMatch(.*)*',
    meta: {
      layout: 'error'
    },
    component: () => import('@/pages/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_FUSION_PATH),
  routes
})

router.beforeEach((to, from) => {
  return applyMiddlewares({ to, from, router })
})

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    // Workaround to window.location typing error
    const win: Window = window;
    win.location = to.fullPath
  }
})

export default router
