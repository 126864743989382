export class Ok<T> {
  readonly ok = true
  readonly data: T
  readonly headers: Headers

  constructor(data: T, headers?: Headers) {
    this.data = data
    if (headers) this.headers = headers
  }
}
