/** matchPropType
 *
 * @param {Object} styles Mapping of classes associated to a key value
 * @param {Generic Type} props Component props to match with class mapping
 * @param {boolean} subStyle Map deep level indicator
 * @returns Concatenated classes based on styles mapping matching props values
 */

interface Styles {
  property: string
  // skipcq: JS-0323 Any à traiter
  values: any
}

const matchPropType = <P>(
  styles: Styles[],
  props: P,
  subStyle: boolean
): string | undefined => {
  if (!styles || !props) return ''

  let concatenatedClasses: string | undefined = ''

  styles.forEach((style) => {
    let classesValues: string | undefined = ''

    //Si sous-niveau de prop matching necessaire
    if (subStyle) {
      //Initialiser une variable pour savoir si un nom de prop existe dans les valeurs de la prop
      let matchProp: string | undefined = ''
      //Verifier les valeurs de la prop ciblée
      Object.keys(props).forEach((k) => {
        if (style[k as keyof typeof style] && props[k as keyof typeof props]) {
          matchProp = style[k as keyof typeof style]
        }
      })
      if (matchProp) {
        classesValues = matchProp
      } else {
        //Rendre la valeur par default si aucune prop match dans les valeurs
        classesValues = style['default' as keyof typeof style] as
          | string
          | undefined
      }
    } else {
      const styleValues = style.values
      const propMatching = props[style.property as keyof typeof props]

      if (typeof propMatching === 'boolean') {
        if (styleValues[propMatching ? 0 : 1]) {
          classesValues = styleValues[propMatching ? 0 : 1]
        }
      }
      if (typeof propMatching === 'string' && styleValues[propMatching]) {
        classesValues = styleValues[propMatching]
      }
      //Si la valeur est un objet, réutiliser la fonction mais avec la prop sous-niveau
      if (typeof styleValues[propMatching] === 'object') {
        classesValues = matchPropType([styleValues[propMatching]], props, true)
      }
    }

    concatenatedClasses
      ? (concatenatedClasses = `${concatenatedClasses.trim()} ${classesValues}`)
      : (concatenatedClasses = classesValues)
  })

  return concatenatedClasses.trim()
}

export const getStyle = <P>(props: P, styles: Styles[]) => {
  return matchPropType(styles, props, false)
}
