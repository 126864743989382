import { Moment } from '@/types/moment.interface'
import { Project } from '@/types/project.interface'

import { contributionStates } from '@/constants/contributions'

export interface IContribution {
  id: number
  state: keyof typeof contributionStates
  amount: number
  created_at: Date | string

  project?: Project

  projectId: string
  userId: string
}
// skipcq: JS-0322
export interface Contribution extends IContribution {}

// skipcq: JS-0327
export class Contribution implements IContribution {
  public created_at: Moment

  constructor(props: IContribution) {
    Object.assign(this, {
      ...props,
      project: props.project ? new Project(props.project) : undefined,
      created_at: props.created_at ? new Moment(props.created_at) : undefined
    })
  }
}
