<template>
  <div class="px-2 py-4 mt-4 bg-teal-700 border-t-2 border-b-2 border-teal-100">
    <h3
      class="px-3 mb-2 text-xs font-semibold leading-4 tracking-wider text-white uppercase"
    >
      Valider mon compte
    </h3>
    <template v-if="fawkesStore.user?.filled_in_profile">
      <RouterLink
        to="/mon-compte/dashboard/profile"
        class="flex items-center px-2 py-2 text-xs font-medium text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-800 focus:outline-none focus:bg-teal-900"
      >
        <svg
          class="w-4 h-4 mr-2 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          ></path>
        </svg>
        Compléter vos informations personnelles
      </RouterLink>
    </template>
    <template
      v-if="
        !fawkesStore.user?.at_least_one_pending_document &&
        fawkesStore.user?.downgraded
      "
    >
      <RouterLink
        to="/mon-compte/dashboard/documents"
        class="flex items-center px-2 py-2 text-xs font-medium text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-800 focus:outline-none focus:bg-teal-900"
      >
        <svg
          class="w-4 h-4 mr-2 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 17a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 17m-7-5l3-3m0 0l3 3m-3-3v12"
          ></path>
        </svg>
        Mettre à jour mes pièces justificatives
      </RouterLink>
    </template>
    <template v-else-if="!fawkesStore.user?.filled_in_documents">
      <RouterLink
        to="/mon-compte/dashboard/documents"
        class="flex items-center px-2 py-2 text-xs font-medium text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-800 focus:outline-none focus:bg-teal-900"
      >
        <svg
          class="w-4 h-4 mr-2 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 17a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 17m-7-5l3-3m0 0l3 3m-3-3v12"
          ></path>
        </svg>
        Charger vos pièces justificatives
      </RouterLink>
    </template>
    <template
      v-if="fawkesStore.user?.legal && fawkesStore.user?.must_send_ubo_declaration"
    >
      <RouterLink
        to="/mon-compte/dashboard/ubo/edit"
        class="flex items-center px-2 py-2 text-xs font-medium text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-800 focus:outline-none focus:bg-teal-900"
      >
        <svg
          class="w-4 h-4 mr-2 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
          ></path>
        </svg>
        Compléter les informations des bénéficiaires effectifs
      </RouterLink>
    </template>
    <template v-if="!fawkesStore.user?.suitability_test_submitted">
      <RouterLink
        to="/mon-compte/dashboard/suitability_tests"
        class="flex items-center px-2 py-2 text-xs font-medium text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-800 focus:outline-none focus:bg-teal-900"
      >
        <svg
          class="w-4 h-4 mr-2 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
          ></path>
        </svg>
        Compléter le questionnaire d'adéquation
      </RouterLink>
    </template>
    <template v-if="fawkesStore.user?.investor_status === 'not_compliant'">
      <RouterLink
        to="/mon-compte/dashboard/suitability_tests"
        class="flex items-center px-2 py-2 text-xs font-medium text-gray-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-800 focus:outline-none focus:bg-teal-900"
      >
        <svg
          class="w-4 h-4 mr-2 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
          ></path>
        </svg>
        Rectifier mon questionnaire d'adéquation
      </RouterLink>
    </template>
  </div>
</template>
<script setup lang="ts">
import { useFawkesStore } from '@/stores'

const fawkesStore = useFawkesStore()
</script>
