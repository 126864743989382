import { Router, RouteLocationNormalized } from 'vue-router'

interface MiddlewareProps {
  from: RouteLocationNormalized,
  router: Router,
  to: RouteLocationNormalized
}

export const applyMiddlewares = async function ({ to, from, router }: MiddlewareProps) {
  if (to.meta.middlewares) {
		const middlewares = to.meta.middlewares as Array<(params: MiddlewareProps) => RouteLocationNormalized | boolean>
		
		const context = { from, router, to }
		
		for (const middleware of middlewares) {
			const redirect = await middleware(context)
			if (typeof redirect !== 'boolean') return redirect
		}
	}

	return true
}
