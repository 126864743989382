export const subtractYears = (date: Date, years: number) => {
  date.setFullYear(date.getFullYear() - years)

  return date.toISOString().split('T')[0]
}

export const dateMinus110Years = subtractYears(new Date(), 110)

export const dateMinus18Years = subtractYears(new Date(), 18)
export const dateToday = subtractYears(new Date(), 0)
