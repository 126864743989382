import { ProjectPost } from '@/types/projectPost.interface'
import { Moment } from '@/types/moment.interface'
import { projectStates } from '@/constants/projects'

export interface IProject {
  id: number
  state:
    | 'not_published'
    | 'published'
    | 'succeeded'
    | 'not_succeeded'
    | 'reimbursed'
  name: string
  description: string
  image: string
  amount: number
  goal: number
  annual_output: number
  interest: number
  projectPosts: ProjectPost[]

  decision_date: Date | string
  repayment_month: Date | string
  created_at: Date | string
}

// skipcq: JS-0322
export interface Project extends IProject {}

// skipcq: JS-0327
export class Project implements IProject {
  public decision_date: Moment
  public repayment_month: Moment
  public created_at: Moment

  constructor(props: IProject) {
    if (props.projectPosts)
      props.projectPosts = props.projectPosts
        .map((p) => new ProjectPost(p))
        .sort((a, b) => b.created_at.diff(a.created_at))

    Object.assign(this, {
      ...props,
      decision_date: props.decision_date
        ? new Moment(props.decision_date)
        : undefined,
      repayment_month: props.repayment_month
        ? new Moment(props.repayment_month)
        : undefined,
      created_at: props.created_at ? new Moment(props.created_at) : undefined
    })
  }

  public get fr() {
    return {
      state: projectStates[this.state].title
    }
  }

  public get duration() {
    return Math.abs(this.repayment_month.month - this.created_at.month)
  }
}
