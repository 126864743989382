import { getStyle } from '@/logic/useStyle'

export const codeInputGroupClasses = <T>(props: T) => {
  const sizeMapping = [
    {
      property: 'isDisabled',
      values: ['bg-gray-50 ring-gray-200', '']
    },
    {
      property: 'color',
      values: {
        primary:
          'text-gray-900 border-primary-600 ring-primary-600 focus:!ring-1 focus:border-primary-600 placeholder:text-grey-500',
        gray: 'text-gray-900 border-gray-600 ring-primary-600 focus:!ring-1 focus:border-gray-600 placeholder:text-grey-500',
        red: 'text-red-900 border-red-500 focus:ring-red-500 focus:border-red-500 focus:!ring-1 placeholder:text-red-500',
        green:
          'text-green-900 border-green-500 focus:ring-green-500 focus:border-green-500 focus:!ring-1 placeholder:text-grey-500'
      }
    }
  ]
  return getStyle(props, sizeMapping)
}
