import { ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import {
  Ok,
  Result,
  IFormTemplate,
  ILegalForms,
  ICountries,
  IDegrees,
  IProfessions
} from '@/types'

import { fetchFormTemplate } from '@/api/formTemplateApi'

export const useFormTemplateStore = defineStore('formTemplate', () => {
  const countries: Ref<ICountries[]> = ref([])
  const degrees: Ref<IDegrees[]> = ref([])
  const professions_classification_groups: Ref<IProfessions[]> = ref([])
  const legal_forms: Ref<ILegalForms[]> = ref([])

  const getTemplate = async () => {
    const response: Result<IFormTemplate> = await fetchFormTemplate()

    if (!response.ok) return response
    countries.value = response.data.countries
    degrees.value = response.data.degrees
    professions_classification_groups.value =
      response.data.professions_classification_groups
    legal_forms.value = response.data.legal_forms
    return new Ok(true)
  }
  return {
    getTemplate,
    countries,
    degrees,
    professions_classification_groups,
    legal_forms
  }
})
