import { defineStore, storeToRefs } from 'pinia'
import { ref, Ref, computed, watch } from 'vue'
import { validations } from '@/logic/useInputValidation'
import { ONBOARDING_ABOUT_STEPS } from '@/tools/onboarding-about'
import {
  IOnboardingField,
  IOnboardingSection,
  IOnboardingStep
} from '@/types/form.interface'
import { useUserStore } from '@/stores'
import { getProperty, isEmpty } from '@/logic/useObject'
import { ONBOARDING_INVESTMENTS_STEPS } from '@/tools/onboarding-investments'
import {
  ONBOARDING_KNOWLEDGEABLE_PP_STEPS,
  ONBOARDING_KNOWLEDGEABLE_PM_STEPS
} from '@/tools/onboarding-knowledgeable'
import { ONBOARDING_EXPERIENCE_STEPS } from '@/tools/onboarding-knowledge-experiences'
import { ONBOARDING_RISKS_STEPS } from '@/tools/onboarding-risks'
import { ONBOARDING_SITUATION_STEPS } from '@/tools/onboarding-financial-situation'
import { Err } from '@/types'
import { ONBOARDING_CONFIRMATION } from '@/tools/onboarding-confirmation'
import { ONBOARDING_SIMULATION } from '@/tools/onboarding-simulation'
import { formattersIn } from '@/logic/useInputFormatters'

export const useOnboardingStore = defineStore('onboarding', () => {
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  const currentSectionId = ref('')
  const currentFormData: Ref<{ [key: string]: any }> = ref({})
  const serverData: Ref<{ [key: string]: any }> = ref({})
  const apiErrors: Ref<{ stepId?: string; error: Err }[]> = ref([])

  const validatedSteps: Ref<string[]> = ref([])

  const parseSteps = (steps: IOnboardingStep[]) => {
    // START UTILS

    const getFieldErrors = (field: IOnboardingField<any>) => {
      const errors: Err[] = []

      if (field.validation) {
        field.validation?.forEach((validator) => {
          const validationFunction = validations[validator.id]

          if (validationFunction) {
            const result = validationFunction(
              currentFormData.value[
                field.id as keyof typeof currentFormData.value
              ],
              validator.params
            )

            if (result instanceof Err) errors.push(result)
          }
        })
      }

      return errors
    }

    const parseFields = (fields: Array<IOnboardingField<any>>) => {

      const parsedFields = [...fields].map((field) => {
        const parsedField = {
          ...field,
          errors: getFieldErrors(field),
          isDisabled:
            field.displayCondition &&
            !field.displayCondition(currentFormData.value, serverData.value)
        }
        return parsedField
      })

      return parsedFields
    }

    const isStepCompleted = (step: IOnboardingStep) => {
      if (!user.value) return false
      if (step.completedCondition)
        return step.completedCondition(currentFormData.value)

      let isComplete = true

      step.fields
        .filter((field) => !field.isDisabled)
        .forEach((field) => {
          const matchingUserValue =
            field.isVirtual && currentFormData.value[field.id]
              ? currentFormData.value[field.id]
              : getProperty(user.value, field.id.split('.'))
          if (isEmpty(matchingUserValue) && field.isRequired !== false) {
            isComplete = false
          }
        })

      return isComplete
    }

    const isStepCurrent = (
      step: IOnboardingStep,
      previousStep: IOnboardingStep | null
    ) => {
      return (!previousStep || previousStep.isCompleted) &&
        !(
          isStepCompleted(step) &&
          (validatedSteps.value.length === 0 ||
            validatedSteps.value.includes(step.id))
        )
        ? true
        : false
    }

    const isStepDisplayed = (step: IOnboardingStep) => {
      return (
        !step.displayCondition ||
        step.displayCondition(
          currentFormData.value,
          serverData.value,
          user.value
        )
      )
    }

    // END UTILS

    let previousStep: IOnboardingStep | null = null
    let currentStep = 0

    const parsedSteps = [...steps].map((step, i) => {
      let parsedStep = {
        ...step,
        fields: parseFields(step.fields)
      }

      const fieldsErrors = parsedStep.fields
        .filter((field) => !field.isDisabled)
        .reduce((all: Err[], current) => [...all, ...current.errors], [])

      const formErrors = apiErrors.value
        .filter((error) => !error.stepId || error.stepId === step.id)
        .map((e) => e.error)
      const isDisplayed = isStepDisplayed(parsedStep)
      const isCurrent = isStepCurrent(parsedStep, previousStep)
      const isValidated = validatedSteps.value.includes(step.id)

      parsedStep = {
        ...parsedStep,
        isCurrent: !currentStep && isCurrent,
        isCompleted: isStepCompleted(parsedStep),
        isValidated,
        isDisplayed,
        errors: [...formErrors, ...fieldsErrors]
      }

      if (parsedStep.isDisplayed) previousStep = parsedStep
      if (parsedStep.isDisplayed && isCurrent && !currentStep) currentStep = i

      return parsedStep
    })

    return parsedSteps
  }

  const parseSections = (sections: IOnboardingSection[]) => {
    // START UTILS

    const isSectionCompleted = (section: IOnboardingSection) => {
      const isCompletedCondition = section.completedCondition
        ? section.completedCondition()
        : true

      const availableSteps = section.steps.filter((step) => step.isDisplayed)

      return (
        availableSteps.filter((step) => step.isCompleted).length ===
          availableSteps.length && isCompletedCondition
      )
    }

    const isSectionAvailable = (
      section: IOnboardingSection,
      previousSection: IOnboardingSection | null
    ) => {
      return (
        !previousSection || section.isCompleted || previousSection.isCompleted
      )
    }

    // END UTILS

    let previousSection: IOnboardingSection | null = null

    const parsedSections = [...sections].map((section) => {
      const parsedSection = {
        ...section,
        isAvailable: isSectionAvailable(section, previousSection),
        isCompleted: isSectionCompleted(section),
        isCurrent: section.id === currentSectionId.value
      }

      previousSection = parsedSection
      return parsedSection
    })

    return parsedSections
  }

  const sections: Ref<Array<IOnboardingSection>> = computed(() => {
    if (!user.value) return []

    return parseSections([
      {
        id: 'redirect',
        order: 0,
        name: 'onboarding',
        isSubsidiary: true,
        steps: []
      },

      {
        id: 'home',
        order: 1,
        name: 'onboarding-home',
        isSubsidiary: true,
        steps: []
      },

      {
        id: 'about',
        order: 2,
        name: 'onboarding-about',
        title: 'À propos de vous',
        steps: parseSteps(ONBOARDING_ABOUT_STEPS)
      },

      {
        id: 'knowledgeable-start',
        order: 3,
        name: 'onboarding-knowledgeable-start',
        isSubsidiary: true,
        completedCondition: () => {
          return (
            user.value?.form_details?.is_sophisticated_user_choice !== null &&
            user.value?.form_details?.is_sophisticated_user_choice !== undefined
          )
        },
        steps: []
      },

      ...(user.value?.form_details?.is_sophisticated_user_choice === false
        ? []
        : [
            {
              id: 'knowledgeable',
              order: 4,
              name: 'onboarding-knowledgeable-questions',
              isSubsidiary: true,
              completedCondition: () => {
                return (
                  user.value?.form_details?.is_sophisticated !== null &&
                  user.value?.form_details?.is_sophisticated !== undefined
                )
              },
              steps: parseSteps(
                user.value?.form_details?.type === 'natural'
                  ? ONBOARDING_KNOWLEDGEABLE_PP_STEPS
                  : ONBOARDING_KNOWLEDGEABLE_PM_STEPS
              )
            }
          ]),

      ...(user.value?.form_details?.is_sophisticated_user_choice === false
        ? []
        : [
            {
              id: 'onboarding-knowledgeable-validation',
              order: 5,
              name: 'onboarding-knowledgeable-validation',
              isSubsidiary: true,
              completedCondition: () => {
                return (
                  user.value?.form_details?.is_sophisticated !== null &&
                  user.value?.form_details?.is_sophisticated !== undefined
                )
              },
              steps: []
            }
          ]),

      {
        id: 'investments',
        order: 6,
        name: 'onboarding-investments',
        title: 'Vos investissements',
        steps: parseSteps(ONBOARDING_INVESTMENTS_STEPS)
      },

      ...(user.value?.form_details?.is_sophisticated
        ? []
        : [
            {
              id: 'experience',
              order: 7,
              name: 'onboarding-experience-knowledge',
              title: 'Connaissance & expérience',
              steps: parseSteps(ONBOARDING_EXPERIENCE_STEPS)
            }
          ]),

      ...(user.value?.form_details?.is_sophisticated
        ? []
        : [
            {
              id: 'risks',
              order: 8,
              name: 'onboarding-risks',
              title: 'Compréhension des risques',
              steps: parseSteps(ONBOARDING_RISKS_STEPS)
            }
          ]),

      ...(user.value?.form_details?.is_sophisticated
        ? []
        : [
            {
              id: 'financial',
              order: 9,
              name: 'onboarding-financial-situation',
              title: 'Votre simulation',
              steps: parseSteps(ONBOARDING_SITUATION_STEPS)
            }
          ]),

      ...(user.value?.form_details?.is_sophisticated
        ? []
        : [
            {
              id: 'simulation',
              order: 10,
              name: 'onboarding-simulation',
              title: 'Résulat de la simulation',
              isSubsidiary: true,
              steps: parseSteps(ONBOARDING_SIMULATION)
            }
          ]),

      ...(user.value?.form_details?.is_sophisticated
        ? []
        : [
            {
              id: 'confirmation',
              order: 11,
              name: 'onboarding-confirmation',
              isSubsidiary: true,
              title: 'Rappel des risques',
              steps: parseSteps(ONBOARDING_CONFIRMATION)
            }
          ]),

      {
        id: 'documents',
        order: 12,
        name: 'onboarding-documents',
        title: 'Vos justificatifs',
        completedCondition: () => {
          return false
        },
        steps: [],
        isCompleted: false
      }
    ])
  })

  const currentSection = computed(() => {
    return sections.value.find((section) => section.isCurrent)
  })

  const updateStoreData = (formData: any) => {
    currentFormData.value = formData
  }

  const updateServerData = (data: { [key: string]: any }) => {
    serverData.value = {
      ...serverData.value,
      ...data
    }
  }

  const setError = (error: Err | null, stepId?: string) => {
    apiErrors.value = error ? [{ stepId, error }] : []
  }

  const clearErrors = () => {
    apiErrors.value = []
  }

  // Mise à jour des valeurs du formulaire avec celles du serveur
  watch(
    [user, currentSection],
    () => {
      if (!user.value) {
        serverData.value = {}
        return
      }

      if (!currentSection.value) return

      if (currentSection.value) {
        currentSection.value.steps.forEach((step) => {
          step.fields
            .filter((field) => !field.isVirtual)
            .forEach((field) => {
              // Valeur serveur
              let matchingUserValue: any = getProperty(
                user.value,
                field.id.split('.')
              )

              const defaultValue = isEmpty(field.default)
                ? null
                : typeof field.default === 'function'
                ? field.default(user.value)
                : field.default

              if (field.formatters) {
                matchingUserValue = formattersIn(
                  matchingUserValue,
                  field.formatters
                )
              }

              serverData.value[field.id] = isEmpty(matchingUserValue)
                ? defaultValue
                : matchingUserValue
            })
        })
      }
    },
    { immediate: true }
  )

  setTimeout(() => {
    validatedSteps.value = sections.value.reduce((all: string[], section) => {
      return [
        ...all,
        ...(section.steps
          .filter((step) => step.isCompleted)
          ?.map((step) => step.id) ?? [])
      ]
    }, [])
  }, 300)

  return {
    apiErrors,
    sections,
    serverData,
    currentSectionId,
    currentSection,
    validatedSteps,
    setError,
    clearErrors,
    updateStoreData,
    updateServerData
  }
})
