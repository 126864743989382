import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { initPostHog } from '@/logic/analytics/usePostHog'

import App from '@/App.vue'
// Add CSS
import '@/assets/css/tailwind.css'
import '@/assets/css/main.css'
// Add AppSignal
import { appsignal } from '@/appsignal'
import { errorHandler } from '@appsignal/vue'
// Add layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import OnboardingLayout from '@/layouts/OnboardingLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import ErrorLayout from '@/layouts/ErrorLayout.vue'
import { createGtm } from '@gtm-support/vue-gtm';

// Connect to router
import router from '@/router'
const app = createApp(App)

app.config.errorHandler = errorHandler(appsignal, app)

app.config.globalProperties.posthog = initPostHog()
app.component('DefaultLayout', DefaultLayout)
app.component('DashboardLayout', DashboardLayout)
app.component('OnboardingLayout', OnboardingLayout)
app.component('AuthLayout', AuthLayout)
app.component('ErrorLayout', ErrorLayout)

app.use(router)
app.use(createPinia())
//Don't use GTM on local env
if(import.meta.env.VITE_GTM_ID){
  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID,
      vueRouter: router
    })
  )
}

app.mount('#app')

export default app.config.globalProperties
