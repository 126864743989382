import { ICountries, IDegrees, ILegalForms, IProfessions, SelectOption } from '@/types'

// Set default data
const countriesToSetFirst = ['FR', 'CH', 'BE', 'LU']

export const selectCountryOptions = (
  countries: ICountries[],
  exclude?: string,
  setFirst = countriesToSetFirst
) => {
  let filteredCountries
  if (exclude) {
    filteredCountries = countries.filter(function (el) {
      return el.alpha2 !== exclude
    })
  } else {
    filteredCountries = countries
  }

  const result: SelectOption[] = []
  const topValues: SelectOption[] = []

  if (filteredCountries.length) {
    Object.values(filteredCountries).forEach((country) => {
      result.push({ label: country.name, value: country.alpha2 })
    })
  }

  Object.values(filteredCountries).forEach((country) => {
    if (setFirst.includes(country.alpha2)) {
      country.alpha2 === 'FR'
        ? topValues.unshift({ label: country.name, value: country.alpha2 })
        : topValues.push({ label: country.name, value: country.alpha2 })
    }
  })

  if (topValues.length > 0)
    result.unshift(...topValues, {
      label: '-----',
      value: '',
      isDisabled: true
    })

  return result
}

export const selectDegreesOptions = (degrees: IDegrees[]) => {
  const result: SelectOption[] = []

  if (degrees.length) {
    Object.values(degrees).forEach((degree) => {
      result.push({ label: degree.name, value: degree.id })
    })
  }

  return result
}

export const selectProfessionsOptions = (professions: IProfessions[]) => {
  const result: SelectOption[] = []

  if (professions.length) {
    Object.values(professions).forEach((profession) => {
      result.push({ label: profession.name, value: profession.id })
    })
  }

  return result
}

export const selectLegalFormsOptions = (legal_forms: ILegalForms[]) => {
  const result: SelectOption[] = []

  if (legal_forms.length) {
    Object.values(legal_forms).forEach((legal_form) => {
      result.push({ label: legal_form.name, value: legal_form.id })
    })
  }

  return result
}
