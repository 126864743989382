import globals from '@/main'
import posthog from 'posthog-js'

export const usePostHog = async (): Promise<typeof posthog | null> => {
  const posthogInstance = globals.posthog
  if (!posthogInstance) return null

  // Check during 500ms if PostHog was able to reach API
  const posthogLoaded = await new Promise((resolve) => {
    if (posthogInstance.decideEndpointWasHit) resolve(true)

    let tries = 0

    setInterval(() => {
      if (posthogInstance.decideEndpointWasHit) resolve(true)
      if (tries > 5) resolve(false)
      tries += 1
    }, 100)
  })

  if (!posthogLoaded) return null

  // Wait featureFlags callback for 1s maximum
  const featureFlagsLoaded = await new Promise((resolve) => {
    setTimeout(() => resolve(false), 1000)
    posthogInstance.onFeatureFlags(() => resolve(true))
  })

  return featureFlagsLoaded ? globals.posthog : null
}

export const initPostHog = (): typeof posthog | null => {
  try {
    if (import.meta.env.MODE === 'development') {
      console.info("No PostHog on dev env")
    }
    posthog.init(import.meta.env.VITE_POSTHOG_PROJECT_API_KEY, {
      api_host: import.meta.env.VITE_POSTHOG_API_HOST,
      autocapture: true
    })

    return posthog
  } catch (e) {
    console.log("Error initializing PostHog:", e)
    return null
  }
}
