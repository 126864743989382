import { IOnboardingStep } from '@/types'

export const annualRevenue: IOnboardingStep = {
  id: 'annualRevenue',
  title: `Quel est votre revenu annuel net ?`,

  description: `Revenu annuel total perçu après déduction des coûts et charges associés, des cotisations sociales et des impôts.`,
  tooltip: `Le revenu annuel net est le revenu annuel total que vous percevez après déduction des coûts et charges, des cotisations sociales et des impôts liés.`,
  modal: 'annual-revenue',
  fields: [
    {
      id: 'form_details.net_annual_revenue_amount',
      label: `Montant`,
      componentProps: {
        type: 'currency',
        mode: 'numeric',
        isRequired: true,
        formatters: ['cents-value'],
        helpers: [{ type: 'default', icon: { name: 'euro' } }]
      }
    }
  ]
}

export const liquidAssets: IOnboardingStep = {
  id: 'liquidAssets',
  title: `Quel est le montant total de vos actifs liquides ?`,

  description: `A l'exclusion de vos biens immobiliers et fonds de pension.`,
  tooltip: `Le total de vos actifs liquides est la somme du total des liquidités que vous détenez sur des comptes d'épargne et courants et de la valeur de ses actifs pouvant être facilement et rapidement convertis en liquidités.`,
  modal: 'liquid-assets',
  fields: [
    {
      id: 'form_details.liquid_assets_amount',
      label: `Montant`,
      componentProps: {
        type: 'currency',
        mode: 'numeric',
        isRequired: true,
        formatters: ['cents-value'],
        helpers: [{ type: 'default', icon: { name: 'euro' } }]
      }
    }
  ]
}

export const financialCommitments: IOnboardingStep = {
  id: 'financialCommitments',
  title: `Quel est le montant de vos engagements financiers annuels ?`,
  description: `Emprunts, créances, etc.`,
  tooltip: `Vos engagements financiers annuels comprennent l'ensemble des dépenses que vous vous êtes engagés à payer sur une année civile donnée`,
  modal: 'commitments',
  fields: [
    {
      id: 'form_details.financial_commitments_amount',
      label: `Montant`,
      componentProps: {
        type: 'currency',
        mode: 'numeric',
        isRequired: true,
        formatters: ['cents-value'],
        helpers: [{ type: 'default', icon: { name: 'euro' } }]
      }
    }
  ]
}

export const ONBOARDING_SITUATION_STEPS = [
  annualRevenue,
  liquidAssets,
  financialCommitments
]
