import { IOnboardingStep } from '@/types'

export const sharesDefinition: IOnboardingStep = {
  id: 'sharesDefinition',
  title: `Une action correspond à :`,

  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.shares_definition',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Une part du capital d’une entreprise',
            value: 'share_in_the_capital_of_a_company'
          },
          {
            label: 'Une part d’emprunt émis par une entreprise',
            value: 'share_of_a_loan_issued_by_a_company'
          },
          {
            label: 'Je ne sais pas',
            value: 'i_dont_know'
          }
        ]
      }
    }
  ]
}

export const boundsDefinition: IOnboardingStep = {
  id: 'boundsDefinition',
  title: `Une obligation correspond à :`,

  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.bounds_definition',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Une part du capital d’une entreprise',
            value: 'share_in_the_capital_of_a_company'
          },
          {
            label: 'Une part d’emprunt émis par une entreprise',
            value: 'share_of_a_loan_issued_by_a_company'
          },
          {
            label: 'Je ne sais pas',
            value: 'i_dont_know'
          }
        ]
      }
    }
  ]
}

export const unlistedRisk: IOnboardingStep = {
  id: 'unlistedRisk',
  title: `L’investissement dans des sociétés non cotées et/ou dans un service de financement participatif présente-t-il un risque de perte en capital totale ou partielle ?`,

  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.capital_loss_risk_definition',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: 'yes'
          },
          {
            label: 'Non',
            value: 'no'
          },
          {
            label: 'Je ne sais pas',
            value: 'i_dont_know'
          }
        ]
      }
    }
  ]
}

export const crowdfundingRisk: IOnboardingStep = {
  id: 'crowdfundingRisk',
  title: `L’investissement dans des titres non cotés et/ou dans un service de financement participatif présente-t-il un risque d’illiquidité (revente ou remboursement non garanti) ?`,

  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.illiquidity_risk_definition',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: 'yes'
          },
          {
            label: 'Non',
            value: 'no'
          },
          {
            label: 'Je ne sais pas',
            value: 'i_dont_know'
          }
        ]
      }
    }
  ]
}

export const riskConfirmation: IOnboardingStep = {
  id: 'riskConfirmation',
  title: `Je confirme avoir bien compris les risques inhérents aux services proposés par Fundimmo, à savoir le risque de perte en capital totale ou partielle et le risque d’illiquidité ?`,

  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.has_understood_platform_illiquidity_and_capital_loss_risks',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true
          },
          {
            label: 'Non',
            value: false
          }
        ]
      }
    }
  ]
}

export const ONBOARDING_RISKS_STEPS = [
  sharesDefinition,
  boundsDefinition,
  unlistedRisk,
  crowdfundingRisk,
  riskConfirmation
]
