import { getStyle } from '@/logic/useStyle'

export const baseInlineClasses = <T>(props: T) => {
  const styleMapping = [
    {
      property: 'type',
      values: {
        primary: 'text-primary-600 hover:text-primary-800  ',
        secondary: 'text-blue-500 hover:text-blue-400'
      }
    },
    {
      property: 'isUnderline',
      values: [
        'underline underline-offset-[6px] decoration-prune-100 hover:decoration-prune-300 decoration-2 transition',
        ''
      ]
    }
  ]
  return getStyle(props, styleMapping)
}
