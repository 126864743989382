import { RouteLocationNormalized } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useOnboardingStore } from '@/stores'
import { storeToRefs } from 'pinia'
import { IOnboardingSection } from '@/types'

export default ({
  to,
  from
}: {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
}) => {

  const onboardingStore = useOnboardingStore()
  const { sections } = storeToRefs(onboardingStore)
  const userStore = useUserStore()
  const { user, dashboard_url } = userStore

  const userState = user?.form_details?.states
  const userVerification = user?.verification_status

  // Conditions to redirect user
  const conditions = [
    {
      condition:
        from.name !== 'error' &&
        to.name !== 'error' &&
        (userVerification === 'denied' || userVerification === 'manual_review'),
      value: () => {
        return { name: 'error' }
      }
    },
    {
      condition:
        userState?.fawkes_completed &&
        userState?.form_completed &&
        userState?.payment_service_completed &&
        userState?.kyc_completed &&
        userVerification === 'verified',
      value: () => {
        if (from.name === 'auth' && dashboard_url) {
          userStore.logOut()
          window.location.href = dashboard_url
          return false
        }
        if (to.name === 'onboarding-end') return true
        return { name: 'onboarding-end' }
      }
    },
    {
      condition:
        userState?.form_completed &&
        userState?.kyc_completed &&
        userVerification !== 'denied' &&
        userVerification !== 'manual_review',
      value: () => {
        if (to.name === 'onboarding-pending') return true
        return { name: 'onboarding-pending' }
      }
    },
    {
      condition:
        userVerification === 'unverified' && to.name !== 'onboarding-pending',
      value: () => {
        const targetSection = sections.value.find(
          (section) => to.name === section.name
        )

        let lastAvailableSection: IOnboardingSection = sections.value[0]
        let redirection: IOnboardingSection | null = null

        sections.value.forEach((section) => {
          if (section.isAvailable) lastAvailableSection = section
        })

        if (!targetSection || !lastAvailableSection)
          return { name: 'onboarding-home' }

        if (
          targetSection.name === 'onboarding' ||
          targetSection.order >
            (lastAvailableSection.isCompleted
              ? lastAvailableSection.order + 1
              : lastAvailableSection.order) ||
          !targetSection.isAvailable
        ) {
          redirection = lastAvailableSection
        }

        if (redirection && to.name !== redirection.name) {
          return { name: redirection.name }
        }

        return null
      }
    }
  ]

  // Parse table and return first value associated
  for (const { condition, value } of conditions) {
    if (condition) {
      return value()
    }
  }
  return true
}
