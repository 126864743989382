import { getStyle } from '@/logic/useStyle'

export const baseAlertCardClasses = <T>(props: T) => {
  const styleMapping = [
    {
      property: 'color',
      values: {
        blue: 'border-blue-600 text-blue-600',
        green: 'border-green-600 text-green-600',
        yellow: 'border-yellow-400 text-yellow-400',
        red: 'border-red-600 text-red-600'
      }
    },
    {
      property: 'messageType',
      values: {
        default: 'bg-white',
        bug: 'bg-yellow-400'
      }
    }
  ]
  return getStyle(props, styleMapping)
}

export const baseAlertCardIconName = <T>(props: T) => {
  const styleMapping = [
    {
      property: 'color',
      values: {
        blue: 'info-circle',
        green: 'check',
        yellow: 'alert-triangle',
        red: 'exclamation-circle'
      }
    }
  ]
  return getStyle(props, styleMapping)
}
