import { IAddress, ICompany } from '@/types'
import countries from 'i18n-iso-countries'
import { Ternary } from './input2.interface'

export interface IUserDetails {
  type: 'natural' | 'legal' | null
  civility: 'mr' | 'ms' | null
  fawkes_id?: string | null
  fawkes_completed?: boolean
  capacity?: string | null
  first_name: string | null
  last_name: string | null
  birth_name: string | null

  nationality: countries.Alpha2Code | null

  birthdate: string | null
  birth_country: countries.Alpha2Code | null
  birth_city: string | null
  birth_zip_code: string | null

  address: IAddress | null
  fiscal_address: IAddress | null

  us_person: Ternary
  pep: Ternary
  pep_description: string | null
  has_cgp: Ternary
  company: ICompany | null

  mp_id?: string | null
  states?: {
    form_completed?: boolean
    kyc_completed?: boolean
    fawkes_completed?: boolean
    payment_service_completed?: boolean
  }

  is_risk_profile: boolean | null
  is_sophisticated: boolean | null
  is_sophisticated_user_choice: boolean | null
  has_confirmed_financial_situation_accuracy: boolean | null
}

export interface IUser {
  id: string
  email: string

  eula_agreement_at?: string
  payment_service_agreement_at?: string
  email_validated_at?: Date
  verification_status?:
    | 'denied'
    | 'verified'
    | 'unverified'
    | 'pending'
    | 'manual_review'
  refused_reason?: string
  phone_number?: string
  phone_number_validated_at?: Date
  affiliation_code?: string
  form_details: IUserDetails
}

// skipcq: JS-0322
export interface User extends IUser {}

// skipcq: JS-0327
export class User implements IUser {
  constructor(props: IUser) {
    Object.assign(this, props)
  }

  public get acceptedCGU() {
    return this.eula_agreement_at && this.payment_service_agreement_at
  }
}
