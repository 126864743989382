import { getStyle } from '@/logic/useStyle'

export const baseAvatarClasses = <T>(props: T) => {
  const styleMapping = [
    {
      property: 'color',
      values: {
        gray: 'bg-gray-500 text-white',
        lightGray: 'bg-gray-300 text-gray-700 hover:text-gray-500'
      }
    },
    {
      property: 'size',
      values: {
        small: 'w-10 h-10 text-sm',
        medium: 'w-12 h-12 text-xl',
        large: 'w-14 h-14 text-xl'
      }
    }
  ]
  return getStyle(props, styleMapping)
}
