// Transforms object in plain array
export const spreadObject = <T>(items: T[][]): T[] => {
  return Object.values(items).reduce((total, values): T[] => {
    return [
      ...total,
      ...(Array.isArray(values) ? values : spreadObject(values))
    ] as T[]
  }, [])
}

// Returns an object with values as key
export const objectByKey = <T>(array: T[], key: string) => {
  return array.reduce((all, current) => {
    const itemValue = current[key as keyof typeof current]
    const keyId =
      typeof itemValue === 'number' ? `id-${itemValue}` : (itemValue as string)

    return {
      ...all,
      [keyId]: [...(all[keyId as keyof typeof all] || []), current]
    }
  }, {})
}

type ObjectValue =
  | string
  | number
  | Date
  | null
  | boolean
  | undefined
  | string[]

type ObjectWithValues = {
  [key: string]: ObjectValue | ObjectWithValues
}

export const mapDefaults = <T extends ObjectWithValues, J>(
  defaults: T,
  items?: J | null
) => {
  if (!items) return defaults

  return Object.entries(defaults).reduce((total, d) => {
    const value = items[d[0] as keyof typeof items]

    return {
      ...total,
      [d[0]]: value !== undefined ? value : d[1]
    }
  }, {}) as T | J
}

export const getProperty = <T>(item: T, keys: (string | undefined)[]) => {
  let schema: T = item
  const path = keys.filter((k) => k) as string[]

  path.forEach((level, i) => {
    if (!schema[level as keyof typeof schema] && i < path.length - 1)
      schema[level as keyof typeof schema] = {} as T[keyof typeof schema]

    schema = schema[level as keyof typeof schema] as T
  })

  return schema as T[keyof T]
}

export const setProperty = <T>(
  item: T,
  keys: (string | undefined)[],
  value: ObjectValue
) => {
  let schema: T = item
  let i = 0
  const path = keys.filter((k) => k) as string[]

  for (i = 0; i < path.length - 1; i++) {
    schema = schema[path[i] as keyof typeof schema] as T
  }

  schema[path[i] as keyof typeof schema] = value as T[keyof T]

  return schema as T[keyof T]
}

export const isEmpty = (value: ObjectValue | ObjectWithValues) => {
  return (
    value === null ||
    value === '' ||
    value === undefined || (typeof value === 'object' && Object.keys(value).length <= 0) ||
    (Array.isArray(value) && value.length <= 0)
  )
}

export const restructureObject = (object: { [key: string]: any }) => {
  const outputObj = {}

  //skipcq JS-0051
  for (const key in object) {
    const value = object[key]
    const keys = key.split('.')

    let currentLevel: any = outputObj
    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i]

      if (!currentLevel[currentKey]) {
        if (i === keys.length - 1) {
          currentLevel[currentKey] = value
        } else {
          currentLevel[currentKey] = {}
        }
      }

      currentLevel = currentLevel[currentKey]
    }
  }

  return outputObj
}
