<template>
  <TransitionGroup name="transition-slide-down" tag="ul" class="max-w-2xl mx-auto space-y-4">
    <li v-for="(alert, i) in alerts" :key="i" class="list-none">
      <BaseAlertCard
        :content="alert.content"
        :color="alert.color"
        :message-type="alert.messageType || 'default'"
        @close="emit('close', alert)"
      />
    </li>
  </TransitionGroup>
</template>
<script setup lang="ts">
import { BaseAlertCard } from '@/components/molecules'
import { ref } from 'vue'
import { Alert } from '@/types/alert.interface'

const props = withDefaults(
  defineProps<{
    alertList?: Alert[]
  }>(),
  {
    alertList: undefined
  }
)

const alerts = ref(props.alertList)

const emit = defineEmits(['close'])
</script>
