import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { Ok, Movement } from '@/types'

import { fetchMovements } from '@/api/fawkes/movementApi'

export const useMovementsStore = defineStore('movements', () => {
  const items: Ref<Movement[]> = ref([])

  const fetch = async () => {
    const response = await fetchMovements({
      _expand: 'project'
    } as unknown as string[][])
    if (!response.ok) return response

    const { data: movements } = response.data

    items.value = movements

    return new Ok({ movements })
  }

  return {
    fetch,
    items
  }
})
