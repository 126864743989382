import { Ternary } from '@/types/input2.interface'

export const ternaryToBooleanOrNull = (value: Ternary | null) => {
  switch (value) {
    case Ternary.true:
      return true
    case Ternary.false:
      return false
    default:
      return null
  }
}

export const toTernary = (
  value: string | boolean | number | undefined | null
) => {
  return value ? Ternary.true : value === undefined || value === null ? Ternary.unknown : Ternary.false
}
