<template>
  <component
    :is="level"
    class="leading-none font-title"
    :class="`${titleClasses(props)}`"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { titleClasses } from './TitleClasses'
interface BaseTitleProps {
  level: 'h1' | 'h2' | 'h3' | 'h4'
  color?: string
}

const props = withDefaults(defineProps<BaseTitleProps>(), {
  level: 'h1',
  color: 'gray'
})
</script>
