import { computed, ref } from 'vue'
import { useWindowSize, watchDebounced } from '@vueuse/core'

const SCREENS = {
  xs: { id: 'xs', position: 0, max: 0 },
  sm: { id: 'sm', position: 1, max: 640 },
  md: { id: 'md', position: 2, max: 768 },
  lg: { id: 'lg', position: 3, max: 1024 },
  xl: { id: 'xl', position: 4, max: 1280 }
}

export const getMatchingScreens = () => {
  const { width } = useWindowSize()
  return Object.values(SCREENS).filter(
    (s, i) => width.value >= s.max || i === 0
  )
}

export const getCurrentScreen = () => {
  const result = computed(() => {
    const matchingScreens = getMatchingScreens()
    return matchingScreens[matchingScreens.length - 1] ?? null
  })

  return result
}

export const getScreenFromArray = <T>(values: Array<T>) => {
  const result = ref()
  const { width } = useWindowSize()

  const getResult = () => {
    const screen = getCurrentScreen()
    const matchingValues = values.filter(
      (v, i) => i <= screen.value?.position ?? 0
    )

    const value = matchingValues[matchingValues.length - 1]
    result.value = value !== undefined ? value : values[0]
  }

  getResult()
  watchDebounced(width, () => getResult(), { debounce: 100 })

  return {
    result
  }
}
