<template>
  <div
    class="flex items-center w-full h-screen"
    :style="{ backgroundImage: `url(${background})` }"
  >
    <slot />
  </div>
</template>
<script setup lang="ts">
import backgroundLogoPattern from '@/assets/images/bg/bg-logo-pattern.svg'

const background = backgroundLogoPattern
</script>
