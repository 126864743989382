import { IOnboardingStep, User } from '@/types'

export const objectivesStep: IOnboardingStep = {
  id: 'objectivesStep',
  title: `Quels sont vos objectifs en investissant sur Fundimmo ?`,
  description: '(Plusieurs réponses possibles)',
  fields: [
    {
      id: 'form_details.investment_objectives',
      component: 'checkbox-multiple',
      validation: [{ id: 'array-length', params: { min: 1 } }],
      componentProps: {
        name: 'objectives',
        theme: 'card',
        labelPosition: 'left',
        color: 'blue',
        options: [
          {
            label: `Diversifier mon épargne`,
            value: 'diversify_my_savings'
          },
          {
            label: `Percevoir des revenus complémentaires`,
            value: 'additional_income'
          },
          {
            label: `Augmenter mon épargne à moyen-long terme`,
            value: 'increase_my_medium_to_long_term_savings'
          },
          {
            label: `Autre`,
            value: 'other'
          }
        ]
      }
    },
    {
      id: 'form_details.investment_objectives_other',
      label: `Précisez...`,
      isRequired: false,
      displayCondition: (formData) => {
        return (
          Array.isArray(formData['form_details.investment_objectives']) &&
          formData['form_details.investment_objectives'].includes('other')
        )
      }
    }
  ]
}

export const capacityStep: IOnboardingStep = {
  id: 'capacityStep',
  title: `Quelle enveloppe totale envisagez-vous d’investir sur nos projets ?`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.planned_investment_amount',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: `Inférieure à 30k€`,
            value: 'under_30k'
          },
          {
            label: `Entre 30k€ et 100k€`,
            value: 'between_30k_and_100k'
          },
          {
            label: `Supérieure à 100k€`,
            value: 'over_100k'
          }
        ]
      }
    }
  ]
}

export const horizonStep: IOnboardingStep = {
  id: 'horizonStep',
  title: `Quel est l’horizon de détention envisagé de votre portefeuille sur notre plateforme ?`,
  description: `(y incluant d’éventuels réinvestissements)`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.holding_horizon',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: `Inférieur à 1 an`,
            value: 'under_1_year'
          },
          {
            label: `Entre 1 et 5 ans`,
            value: 'between_1_and_5_years'
          },
          {
            label: `Supérieur à 5 ans`,
            value: 'over_5_years'
          }
        ]
      }
    }
  ]
}

export const turnoverStep: IOnboardingStep = {
  id: 'turnoverStep',
  title: `Envisagez-vous d’investir votre épargne dans des sociétés ne versant pas de revenus pendant la durée de votre placement ?`,
  isAutoSubmit: true,
  displayCondition: (formData, serverData, userData) => {
    return !userData?.form_details.is_sophisticated
  },
  fields: [
    {
      id: 'form_details.is_planning_to_invest_savings_in_company_without_income',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: `Oui`,
            value: true
          },
          {
            label: `Non`,
            value: false
          }
        ]
      }
    }
  ]
}

export const needStep: IOnboardingStep = {
  id: 'needStep',
  title: `Êtes-vous susceptible d’avoir besoin des sommes investies sur la plateforme Fundimmo pendant la durée de votre investissement ?`,
  isAutoSubmit: true,
  displayCondition: (formData, serverData, userData) => {
    return !userData?.form_details.is_sophisticated
  },
  fields: [
    {
      id: 'form_details.is_money_needed_during_investment_period',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: `Oui`,
            value: true
          },
          {
            label: `Non`,
            value: false
          }
        ]
      }
    }
  ]
}

export const riskStep: IOnboardingStep = {
  id: 'riskStep',
  title: `Quel niveau de risque de perte de capital êtes vous prêt à supporter ?`,
  isAutoSubmit: true,
  displayCondition: (formData, serverData, userData) => {
    return !userData?.form_details.is_sophisticated
  },
  fields: [
    {
      id: 'form_details.capital_loss_risk_level',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: `Aucun risque`,
            value: 'no_risk'
          },
          {
            label: `Risque modéré`,
            value: 'moderate_risk'
          },
          {
            label: `Risque élevé`,
            value: 'high_risk'
          }
        ]
      }
    }
  ]
}

export const sustainableStep: IOnboardingStep = {
  id: 'sustainableStep',
  title: `Souhaitez-vous privilégier des investissements à impacts positifs sur l’environnement ?`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.is_giving_priority_to_environmental_investment',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: `Oui`,
            value: true
          },
          {
            label: `Non`,
            value: false
          }
        ]
      }
    }
  ]
}

export const typesStep: IOnboardingStep = {
  id: 'typesStep',
  title: `Quelle est la typologie d’opérations recherchée ?`,
  description: '(Plusieurs réponses possibles)',
  fields: [
    {
      id: 'form_details.targeted_operations_typologies',
      component: 'checkbox-multiple',
      validation: [{ id: 'array-length', params: { min: 1 } }],
      componentProps: {
        name: 'operations',
        theme: 'card',
        labelPosition: 'left',
        color: 'blue',
        options: [
          {
            label: `Résidentiel`,
            value: 'residential'
          },
          {
            label: `Commerce`,
            value: 'commercial'
          },
          {
            label: `Logistique`,
            value: 'logistics'
          },
          {
            label: `Bureaux`,
            value: 'offices'
          },
          {
            label: `Hôtellerie`,
            value: 'hotels'
          },
          {
            label: `Autre`,
            value: 'other'
          }
        ]
      }
    },
    {
      id: 'form_details.targeted_operations_typologies_other',
      label: `Précisez...`,
      isRequired: false,
      displayCondition: (formData) => {
        return (
          Array.isArray(
            formData['form_details.targeted_operations_typologies']
          ) &&
          formData['form_details.targeted_operations_typologies'].includes(
            'other'
          )
        )
      }
    }
  ]
}

export const returnStep: IOnboardingStep = {
  id: 'returnStep',
  title: `A durée équivalente, quel couple rendement / risque préférez-vous ?`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.risk_return_trade_off',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: `Inférieur à 7% (risque faible)`,
            value: 'low'
          },
          {
            label: `Entre 7 et 10% (risque modéré)`,
            value: 'moderate'
          },
          {
            label: `Supérieur à 10% (risque élevé)`,
            value: 'high'
          }
        ]
      }
    }
  ]
}

export const durationStep: IOnboardingStep = {
  id: 'durationStep',
  title: `A risque équivalent, quelle est la durée des opérations sur laquelle vous souhaitez investir ?`,
  description: '(Plusieurs réponses possibles)',
  fields: [
    {
      id: 'form_details.targeted_operation_durations',
      component: 'checkbox-multiple',
      validation: [{ id: 'array-length', params: { min: 1 } }],
      componentProps: {
        name: 'duration',
        theme: 'card',
        labelPosition: 'left',
        color: 'blue',
        options: [
          {
            label: `Inférieur à 18 mois`,
            value: 'under_18_months'
          },
          {
            label: `Entre 18 et 36 mois`,
            value: 'between_18_and_36_months'
          },
          {
            label: `Supérieur à 36 mois`,
            value: 'over_36_months'
          }
        ]
      }
    }
  ]
}

export const frequencyStep: IOnboardingStep = {
  id: 'frequencyStep',
  title: `Préférez-vous un versement des intérêts :`,
  isAutoSubmit: true,
  fields: [
    {
      id: 'form_details.interest_payment_preference',
      component: 'radio-multiple',
      componentProps: {
        options: [
          {
            label: `Régulier (semestriel ou annuel)`,
            value: 'periodic'
          },
          {
            label: `Capitalisé (à maturité, en même temps que le capital)`,
            value: 'capitalized'
          }
        ]
      }
    }
  ]
}

export const regionsStep: IOnboardingStep = {
  id: 'regionsStep',
  title: `Dans quelles régions préférez-vous investir ?`,
  description: '(Plusieurs réponses possibles)',
  fields: [
    {
      id: 'form_details.targeted_investment_regions',
      validation: [{ id: 'array-length', params: { min: 1 } }],
      component: 'map',
      componentProps: {
        class: 'max-w-sm mx-auto'
      }
    }
  ]
}

export const hasCGP: IOnboardingStep = {
  id: 'hasCGP',
  title:
    'Bénéficiez-vous des services d’un conseiller en gestion de patrimoine ?',
  fields: [
    {
      id: 'form_details.has_cgp',
      component: 'radio-multiple',
      default: (user: User) => (user.affiliation_code ? true : null),
      componentProps: {
        options: [
          {
            label: 'Oui',
            value: true,
            col: 6
          },
          {
            label: 'Non',
            value: false,
            col: 6
          }
        ]
      }
    }
  ]
}

export const ONBOARDING_INVESTMENTS_STEPS = [
  objectivesStep,
  capacityStep,
  horizonStep,
  turnoverStep,
  needStep,
  riskStep,
  sustainableStep,
  typesStep,
  returnStep,
  durationStep,
  frequencyStep,
  regionsStep,
  hasCGP
]
