import { Ok, Err, IAddressSuggestion, Result } from '@/types'
import { AddressAutofillCore } from '@mapbox/search-js-core'

const MapBoxAutoFill = new AddressAutofillCore({
  accessToken: import.meta.env.VITE_MAPBOX_PUBLIC
})

export const getMapBoxSuggestionsAPI = async (
  search: string,
  sessionToken?: string
): Promise<Result<IAddressSuggestion[]>> => {
  try {
    const response = await MapBoxAutoFill.suggest(search, {
      sessionToken: sessionToken || Math.random().toString(),
      limit: 4
    })

    if (!response.suggestions) return new Err('api-error')


    return new Ok(
      response.suggestions.map(
        (suggestion) =>
          ({
            full_address: suggestion.full_address,
            address: suggestion.address_line1 ?? null,
            address2: suggestion.address_line2 ?? null,
            postal_code: suggestion.postcode ?? null,
            city: suggestion.address_level2 ?? null,
            country: suggestion.country_code?.toUpperCase() ?? null
          } as IAddressSuggestion)
      )
    )
  } catch (e) {
    console.error(e)
    return new Err('api-error')
  }
}
