import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { Ok, Project } from '@/types'

import { getProjects } from '@/api/fawkes/projectApi'

export const useProjectsStore = defineStore('projects', () => {
  const items: Ref<Project[]> = ref([])

  const fetch = async () => {
    const response = await getProjects({
      _embed: 'projectPosts'
    } as unknown as string[][])
    if (!response.ok) return response

    const { data: projects } = response.data

    items.value = projects

    return new Ok({ projects })
  }

  return {
    fetch,
    items
  }
})
