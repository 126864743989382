import { Moment } from '@/types/moment.interface'
import { Project } from '@/types/project.interface'
import { movementStates } from '@/constants/movements'

export interface IMovement {
  id: number
  created_at: Date
  kind: string
  transaction: {
    amount: number
    mean_of_payment: string
    direction: string
  }
  projectId: string
  state: keyof typeof movementStates
  project?: Project
}

// skipcq: JS-0322
export interface Movement extends IMovement {}

// skipcq: JS-0327
export class Movement implements IMovement {
  public created_at: Moment

  constructor(props: IMovement) {
    Object.assign(this, {
      ...props,
      project: props.project ? new Project(props.project) : undefined,
      created_at: new Moment(props.created_at)
    })
  }
}
