// import { useFeatureFlags } from '@/logic/analytics/useFeatureFlags'

// import {
// 	RouteLocationNormalized
// } from 'vue-router'

export default function isNewLoginEnabled() {
	// const featureFlags = await useFeatureFlags()

	// if (
	// 	!featureFlags.isEnabled('new-auth-flow') &&
	// 	to.fullPath.substring(1).split('/')[0] === 'auth'
	// ) {
	// 	window.location.href = 'https://app.fundimmo.com'
	// 	return false
	// }

	return true
}
