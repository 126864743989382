import { Result, Ok, Err, IFormTemplate } from '@/types'
import { fetchApi } from '@/logic/useApi'

export const fetchFormTemplate = async () => {
  const response: Result<{data: IFormTemplate}> = await fetchApi(
    'form_template'
  )
  if (!response.ok) return response
  if (!response.data) return new Err('api-error')
  return new Ok({
    ...response.data.data
  })
}
