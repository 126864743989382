
export enum Ternary {
  false = 0,
  true = 1,
  unknown = 2
}

export type InputValueText = string
export type InputValueNumber = number
export type InputValueBoolean = boolean
export type InputValueTernary = Ternary

export type InputMode = 'text' | 'numeric' | 'tel' | 'search' | 'email' | 'url' | 'decimal'

export type InputCheckboxType = 'checkbox' | 'radio'
export type InputStringType = 'text' | 'email' | 'password' | 'textarea'
export type InputNumberType = 'number'
export type InputDateType = 'date' | 'datetime-local'
