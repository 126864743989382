<template>
  <component :is="layout()" class="text-sm">
    <RouterView v-if="route.matched.length > 0" v-slot="{ Component }">
      <component :is="Component" />
    </RouterView>

    <div v-else class="fixed flex items-center justify-center w-full h-full">
      <BaseIcon
        name="spinner"
        aria-label="Icône de chargement"
        :width="32"
        class="delay-75 animate-spin text-slate-700"
        :class="[isSpinnerVisible ? 'opacity-100' : 'opacity-0']"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import { ref, provide } from 'vue'
import { useRoute } from 'vue-router'
import { BaseIcon } from '@/components/atoms'

const route = useRoute()
const isSpinnerVisible = ref(false)

setTimeout(() => (isSpinnerVisible.value = true), 100)

if (!import.meta.env.PROD) {
  const faviconDark: any = document.getElementById('favicon-dark')
  faviconDark.href = '/favicon-dev-light.png'

  const faviconLight: any = document.getElementById('favicon-light')
  faviconLight.href = '/favicon-dev.png'
}

console.table({
  APP_VERSION: `${import.meta.env.PACKAGE_VERSION ?? 'not set'}`,
  NODE_ENV: `${import.meta.env.NODE_ENV ?? 'not set'}`,
  MODE: `${import.meta.env.MODE ?? 'not set'}`,
  VITE_REVISION: `${import.meta.env.VITE_REVISION ?? 'not set'}`
})

// Charge all icons with glob and provide for each deep children
const icons: object = import.meta.glob(`@/assets/svg/**/*.svg`, { as: 'raw' })
provide('icons', icons)

const layout = () => {
  return `${route.meta.layout ?? 'default'}-layout`
}
</script>
