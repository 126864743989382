import { InputModelValue } from '@/types'
import { ternaryToBooleanOrNull, toTernary } from '@/logic/useTernary'
import { Ternary } from '@/types/input2.interface'

export const centsValue = {
  in: (value: InputModelValue) => {
    if (typeof value === 'number') return value / 100
    if (typeof value === 'string' && value) return Number(value) / 100

    return undefined
  },
  out: (value: InputModelValue) => {
    if (typeof value === 'number') return value * 100
    if (typeof value === 'string' && value) return Number(value) * 100

    return undefined
  }
}

export const asTernary = {
  in: (value: boolean | null) => {
    return toTernary(value)
  },
  out: (value: Ternary) => {
    return ternaryToBooleanOrNull(value)
  }
}

export const asArray = {
  in: (value: any) => {
    if (Array.isArray(value)) {
      return value
    }

    return []
  },
  out: (value: any[]) => {
    return value
  }
}

export const inputFormatters = {
  'cents-value': centsValue,
  'as-ternary': asTernary,
  'as-array': asArray
}

export type InputFormatters = keyof typeof inputFormatters

export const formattersIn = (value: any, formatterIds: InputFormatters[]) => {
  let formattedValue: any = value

  formatterIds.forEach((formatterId) => {
    formattedValue = inputFormatters[formatterId].in(formattedValue)
  })

  return formattedValue
}

export const formattersOut = (value: any, formatterIds: InputFormatters[]) => {
  let formattedValue : any = value

  const reversedIds = [ ...formatterIds ].reverse()

  reversedIds.forEach(formatterId => {
    formattedValue = inputFormatters[formatterId].out(formattedValue)
  })

  return formattedValue
}
