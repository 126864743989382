export interface IFawkesUser {
  id: number
  name_capitalized: string
  first_name: string
  last_name: string
  email: string
  cached_remote_balance_in_euros: number
  investor_status: 'compliant' | 'not_compliant' | 'draft'
  investor_profile: 'dynamic' | 'measured' | 'balanced' | 'not_defined'
  can_access_crowdfunding: boolean
  legal: boolean
  registration_partner: boolean
  downgraded: boolean
  suitability_test_submitted: boolean
  skip_validation: boolean
  at_least_one_pending_document: boolean
  profile_completed: boolean
  filled_in_profile: boolean
  filled_in_documents: boolean
  kyc_and_state_validated: boolean
  must_send_ubo_declaration: boolean
  filled_in_profile_percentage: number
  corporate_name: string | undefined
  blocked_investment: boolean
  blocked_debit: boolean
  can_invest: boolean
  can_debit_wallet: boolean
  fully_filled_in_profile: boolean
  not_compliant: boolean
  kyc_validated: boolean
  current_investment: number
  contributed_projects: number[]
}

// skipcq: JS-0322
export interface FawkesUser extends IFawkesUser {}

// skipcq: JS-0327
export class FawkesUser implements IFawkesUser {
  constructor(props: IFawkesUser) {
    Object.assign(this, props)
  }

  public get can_credit() {
    return !(!this.can_invest || this.blocked_investment)
  }

  public get can_debit() {
    return !(!this.can_debit_wallet || this.blocked_debit)
  }
}
