<template>
  <div class="relative flex items-center">
    <input
      :id="id"
      v-bind="attrs"
      ref="inputRef"
      type="text"
      class="w-full bg-transparent border-0 rounded-md focus:ring-0 focus:outline-none"
      :class="[
        color === 'transparent' ? 'shadow-none' : 'shadow-sm',
        size === 'lg'
          ? 'pt-4 pb-1 min-h-[65px] text-base'
          : size === 'md'
          ? 'py-2 min-h-[65px]'
          : 'py-2 min-h-[38px] text-sm'
      ]"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :required="isRequired"
      :inputmode="mode"
      @focus="toggleFocus(true)"
      @blur="toggleFocus(false)"
    />
  </div>
</template>

<script setup lang="ts">
import { InputModelValue } from '@/types'
import { ref, watch } from 'vue'
import { useCurrencyInput, CurrencyDisplay } from 'vue-currency-input'

interface BaseInputProps {
  id?: string
  modelValue: InputModelValue
  currencyDisplay?: CurrencyDisplay
  mode?: 'numeric' | 'text'
  size?: 'base' | 'md' | 'lg'
  isDisabled?: boolean
  isRequired?: boolean
  placeholder?: string
  attrs?: object
  color?: string
}

const props = withDefaults(defineProps<BaseInputProps>(), {
  id: Math.random().toString(),
  modelValue: '',
  currencyDisplay: 'hidden' as CurrencyDisplay,
  mode: 'text',
  size: 'base',
  isDisabled: false,
  isRequired: false,
  placeholder: '',
  attrs: () => ({}),
  color: 'gray'
})

const { inputRef, numberValue } = useCurrencyInput({
  currency: 'EUR',
  locale: 'fr',
  hideGroupingSeparatorOnFocus: false,
  currencyDisplay: props.currencyDisplay
}, false)

const emit = defineEmits([ 'update:modelValue', 'focus', 'blur', 'change' ])

watch(numberValue, (v) => {
  emit('update:modelValue', v)
})

const isFocused = ref(false)

const toggleFocus = (action: boolean) => {
  isFocused.value = action
  emit(action ? 'focus' : 'blur')
}

const focus = () => {
  if (inputRef.value) inputRef.value.focus()
}

defineExpose({
  focus
})
</script>
