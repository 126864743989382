import { Err, Ok, InputModelValue } from '@/types'

export const isArrayLengthBetween = (
  value: InputModelValue,
  params: { [key: string]: string | number } = {
    min: 0
  }
) => {
  if (!Array.isArray(value)) return new Err('input-array-length')

  if (value.length < Number(params.min)) return new Err('input-array-length')

  return new Ok({ isValid: true })
}

export const isNotMinor = (value: InputModelValue) => {
  const date = new Date(value as string)

  if (!(date instanceof Date) || !isFinite(date.valueOf()))
    return new Err('input-date-error')

  const age = Math.floor((new Date().valueOf() - date.getTime()) / 3.15576e10)

  if (age < 18) return new Err('input-date-minor')

  return new Ok({ isValid: true })
}

export const validations = {
  'array-length': isArrayLengthBetween,
  'not-minor': isNotMinor,
  'string-length': isArrayLengthBetween
} as const
