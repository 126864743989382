export const projectStates = {
  not_published: {
    title: 'Non publié',
    color: 'gray'
  },
  published: {
    title: 'En cours',
    color: 'blue'
  },
  succeeded: {
    title: 'Financé',
    color: 'purple'
  },
  not_succeeded: {
    title: 'Non financé',
    color: 'gray'
  },
  reimbursed: {
    title: 'Remboursé',
    color: 'green'
  }
}
