import { IOnboardingStep } from '@/types'

export const confirmations: IOnboardingStep = {
  id: 'confirmation',
  fields: [
    { id: 'form_details.has_confirmed_test_results', default: 0 },
    { id: 'form_details.has_confirmed_capital_loss_risks', default: 0 },
    { id: 'form_details.has_confirmed_investment_lock_in', default: 0 }
  ]
}

export const ONBOARDING_CONFIRMATION = [confirmations]
