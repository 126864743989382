import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'
import { User, Ok, Err, IUser } from '@/types'
import { authLogAPI } from '@/api/authApi'
import {
  userCreateAPI,
  userSubmitPasswordAPI,
  userEmailValidationAPI,
  userEditAPI,
  userPhoneValidationAPI,
  userRequestResetPassword,
  userResetPassword,
  resendPhoneCodeAPI
} from '@/api/userApi'
import { useAlertsStore } from '@/stores/alerts'

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null)
  const dashboard_url = ref<string | null>(null)
  const registerToken: Ref<string | null> = ref(null)
  const isCodevalidated = ref(false)

  const alertsStore = useAlertsStore()

  const set = (updatedUser: IUser) => {
    user.value = new User(updatedUser)
  }

  const setDashboardUrl = (dashboardUrl: string | null) => {
    dashboard_url.value = dashboardUrl
  }

  const log = async (email: string, password: string) => {
    const response = await authLogAPI(email, password)
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    set(response.data )

    return new Ok(true)
  }

  const logOut = () => {
    user.value = null
    registerToken.value = null

    localStorage.removeItem('auth-token')

    return new Ok(true)
  }

  const create = async (email: string, affiliationCode: string) => {
    isCodevalidated.value = false

    const response = await userCreateAPI(email, affiliationCode)
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    registerToken.value = response.data.token
    set(response.data.user)

    return new Ok(true)
  }

  const validateEmail = async (code: string) => {
    if (!registerToken.value) return new Err('error')

    const response = await userEmailValidationAPI(code, registerToken.value)
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    registerToken.value = response.data.token
    isCodevalidated.value = true
    set(response.data.user)

    return new Ok(true)
  }

  const resendPhoneCode = async () => {
    const response = await resendPhoneCodeAPI()
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    return new Ok(true)
  }

  const validatePhone = async (code: string) => {
    const response = await userPhoneValidationAPI(code)
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    set(response.data.user)

    return new Ok(true)
  }

  const submitPassword = async (password: string) => {
    if (!registerToken.value) return new Err('error')
    const response = await userSubmitPasswordAPI(password, registerToken.value)
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    registerToken.value = null
    localStorage.setItem('auth-token', response.data.token)
    set(response.data.user)

    return new Ok(true)
  }

  const edit = async (user: { [key: string]: any }) => {
    const response = await userEditAPI(user)
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    set(response.data.user)

    return new Ok(true)
  }

  const sendResetPassword = async (email: string) => {
    const response = await userRequestResetPassword(email)
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    return new Ok(true)
  }

  const resetPassword = async (
    passwords: { password: string; password_confirmation: string },
    token: string
  ) => {
    const response = await userResetPassword(passwords, token)
    if (!response.ok) {
      return alertsStore.addError(response)
    }

    return new Ok(true)
  }

  return {
    user,
    dashboard_url,
    registerToken,
    set,
    setDashboardUrl,
    log,
    logOut,
    create,
    edit,
    submitPassword,
    isCodevalidated,
    validateEmail,
    validatePhone,
    sendResetPassword,
    resetPassword,
    resendPhoneCode
  }
})
