import { Contribution, TableDataValue, Result, Ok } from '@/types'
import { fetchApi } from '@/logic/useApi'

export const fetchContributions = async (
  params?: string[][]
): Promise<Result<{ data: Contribution[]; total: number }>> => {
  const response = await fetchApi('contributions', params)
  if (!response.ok) return response

  const contributions = (response.data as TableDataValue[]).map(
    (d) => new Contribution(d as unknown as Contribution)
  )

  return new Ok({
    data: contributions,
    total: Number(response.headers.get('x-total-count'))
  })
}
