import { User, IUser, Result, Ok, Err } from '@/types'
import { fetchApi } from '@/logic/useApi'

export const userCreateAPI = async (email: string, affiliationCode: string) => {
  const response: Result<{ data: IUser; token: string }> = await fetchApi(
    'users',
    { user: { email, affiliation_code: affiliationCode } },
    { method: 'POST' }
  )

  if (!response.ok) return response
  if (!response.data) return new Err('api-error')

  return new Ok({
    user: response.data.data as IUser,
    token: response.data.token
  })
}

export const userEmailValidationAPI = async (
  code: string,
  registerToken: string
) => {
  const response: Result<{ data: IUser; token: string }> = await fetchApi(
    `me/validate`,
    { code },
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${registerToken}`
      }
    }
  )

  if (!response.ok) return new Err('code-invalid')

  return new Ok({
    user: response.data.data as IUser,
    token: response.data.token
  })
}

export const resendPhoneCodeAPI = async () => {
  const token = localStorage.getItem('auth-token')

  const response: Result<{ data: IUser }> = await fetchApi(
    `me/phone_number/resend_code`,
    {},
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`
      }
    }
  )

  if (!response.ok) return new Err('api-error')

  return new Ok(true)
}

export const userPhoneValidationAPI = async (code: string) => {
  const token = localStorage.getItem('auth-token')

  const response: Result<{ data: IUser }> = await fetchApi(
    `me/phone_number/validate`,
    { code },
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`
      }
    }
  )

  if (!response.ok) return new Err('code-invalid')

  return new Ok({
    user: response.data.data as IUser
  })
}

export const userSubmitPasswordAPI = async (
  password: string,
  registerToken: string
) => {
  const response: Result<{ data: IUser; token: string }> = await fetchApi(
    `me/password`,
    { password },
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${registerToken}`
      }
    }
  )

  if (!response.ok) return response

  return new Ok({
    user: response.data.data as IUser,
    token: response.data.token
  })
}

export const userEditAPI = async (user: { [key: string]: any }) => {
  const token = localStorage.getItem('auth-token')

  const response: Result<{ data: User }> = await fetchApi(
    `me`,
    { user },
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`
      }
    }
  )

  if (!response.ok) return response
  if (!response.data) return new Err('user-not-found')

  return new Ok({
    user: new User(response.data.data)
  })
}

export const userRequestResetPassword = async (email: string) => {
  const response: Result<null> = await fetchApi(
    `users/reset_password`,
    { user: { email } },
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    }
  )

  if (!response.ok) return response

  return new Ok(true)
}

export const userResetPassword = async (
  passwords: { password: string; password_confirmation: string },
  token: string
) => {
  const response: Result<{ data: { email: string } }> = await fetchApi(
    `users/reset_password`,
    { user: { ...passwords } },
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`
      }
    }
  )

  if (!response.ok) return response

  return new Ok(true)
}

export const userRequestKYC = async () => {
  const token = localStorage.getItem('auth-token')

  const response: Result<{
    data: {
      expires_at: Date
      shortened_url: string
      uid: string
      url: string
    }
  }> = await fetchApi(
    `me/kyc`,
    {},
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`
      }
    }
  )

  if (!response.ok) return response

  return new Ok({ ...response.data.data })
}
