<template>
  <main class="overflow-hidden">
    <div class="fixed top-0 left-0 z-50 w-full overflow-hidden bg-white">
      <div
        class="px-6 lg:px-0 lg:w-[93%] mx-auto flex flex-row-reverse lg:flex-row items-center lg:justify-between h-32 lg:h-24"
      >
        <BaseIcon
          name="fundimmo-logo-icon"
          :width="34"
          class="hidden lg:mr-6 shrink-0 lg:block"
        />

        <div class="py-4 pl-4 lg:hidden" @click="isMenuActive = true">
          <BaseIcon name="chevron-down" :width="22" />
        </div>

        <TransitionGroup
          role="navigation"
          tag="div"
          name="list"
          class="items-center hidden space-x-1 lg:flex"
        >
          <div
            v-for="(section, i) in visibleSections"
            :key="section.id"
            class="flex items-center"
          >
            <RouterLink
              :aria-label="`Naviguer vers l'étape ${section.title}`"
              class="relative flex items-center duration-500 group"
              :to="{ name: section.name }"
              :class="[
                i === activeIndex
                  ? ' text-blue-600 pr-5'
                  : section.isCompleted
                  ? 'text-gray-800'
                  : 'text-gray-400',
                (section.isAvailable && i !== activeIndex) ||
                section.isCompleted
                  ? 'text-gray-600'
                  : 'pointer-events-none'
              ]"
            >
              <div
                class="absolute top-0 left-0 z-0 h-full bg-white pointer-events-none w-96"
              />

              <div
                class="relative mr-2 text-xs font-medium transition duration-300 border-2 rounded-lg w-7 h-7 shrink-0 font-title"
                :class="[
                  i === activeIndex
                    ? section.isCompleted
                      ? 'text-white border-blue-600 group-hover:text-blue-500 group-hover:duration-100'
                      : 'border-blue-600 text-blue-600'
                    : section.isCompleted
                    ? 'bg-blue-600 text-white border-blue-600'
                    : 'text-gray-400 border-gray-50 ',
                  section.isAvailable &&
                  i !== activeIndex &&
                  !section.isCompleted
                    ? 'text-gray-600 border-gray-400'
                    : ''
                ]"
              >
                <BaseIcon
                  name="check"
                  class="absolute transition duration-300 -translate-x-1/2 top-1/2 left-1/2"
                  :class="[
                    section.isCompleted
                      ? '-translate-y-1/2 group-hover:-translate-y-full group-hover:opacity-0'
                      : '-translate-y-full opacity-0',
                    i === activeIndex && section.isCompleted
                      ? 'text-blue-500'
                      : 'text-white'
                  ]"
                  :width="15"
                />
                <span
                  class="absolute transition duration-300 -translate-x-1/2 top-1/2 left-1/2"
                  :class="[
                    section.isCompleted
                      ? 'opacity-0 translate-y-0 group-hover:-translate-y-1/2 group-hover:opacity-100'
                      : '-translate-y-1/2'
                  ]"
                >
                  {{ i + 1 }}
                </span>
              </div>

              <div
                v-show="i === activeIndex"
                :class="[i === activeIndex ? '' : 'opacity-0']"
                class="relative text-sm font-medium transition duration-300 whitespace-nowrap"
              >
                {{ section.title }}
              </div>
            </RouterLink>
          </div>
        </TransitionGroup>

        <!-- MOBILE SECTION -->
        <div
          class="flex items-center lg:hidden grow"
          @click="isMenuActive = true"
        >
          <div
            v-if="currentSection"
            class="relative w-8 h-8 mr-4 text-sm font-medium transition duration-300 border-2 rounded-lg shrink-0 font-title"
            :class="[
              currentSection?.isCurrent
                ? currentSection?.isCompleted
                  ? 'text-white border-blue-600 group-hover:text-blue-500 group-hover:duration-100'
                  : 'border-blue-600 text-blue-600'
                : currentSection.isCompleted
                ? 'bg-blue-600 text-white border-blue-600'
                : 'text-gray-400 border-gray-50 ',
              currentSection?.isAvailable &&
              !currentSection?.isCurrent &&
              !currentSection?.isCompleted
                ? 'text-gray-600 border-gray-400'
                : ''
            ]"
          >
            <BaseIcon
              name="check"
              class="absolute transition duration-300 -translate-x-1/2 top-1/2 left-1/2"
              :class="[
                currentSection?.isCompleted
                  ? '-translate-y-1/2 group-hover:-translate-y-full group-hover:opacity-0'
                  : '-translate-y-full opacity-0',
                currentSection?.isCurrent && currentSection?.isCompleted
                  ? 'text-blue-500'
                  : 'text-white'
              ]"
              :width="15"
            />
            <span
              class="absolute transition duration-300 -translate-x-1/2 top-1/2 left-1/2"
              :class="[
                currentSection?.isCompleted
                  ? 'opacity-0 translate-y-0 group-hover:-translate-y-1/2 group-hover:opacity-100'
                  : '-translate-y-1/2'
              ]"
            >
              {{ currentSection.order - 1 }}
            </span>
          </div>

          <div class="relative h-10 grow">
            <div
              class="absolute top-0 transition duration-700 ease-in-out"
              :style="{ transform: `translateY(-${40 * activeIndex}px)` }"
            >
              <div
                v-for="(section, i) in visibleSections"
                :key="section.name"
                class="text-gray-600 font-medium text-sm leading-none duration-300 h-10 pt-0.5"
                :class="[i === activeIndex ? 'delay-300' : 'opacity-0']"
              >
                Étape {{ i + 1 }} / {{ visibleSections.length }}
                <p class="text-base font-bold text-gray-700">
                  {{ section.title }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="h-[3px] bg-gray-50">
        <div
          class="h-[3px] bg-blue-500 transition duration-[750ms] ease-in-out"
          :class="[progress ? 'origin-left' : 'origin-right']"
          :style="{ transform: `scaleX(${progress})` }"
        />
      </div>
    </div>
    <div
      v-if="alertsStore.alerts"
      class="fixed z-20 flex justify-center w-full mx-auto overflow-hidden top-36"
    >
      <ListAlertCard :alert-list="alertsStore.alerts" @close="(alert) => removeAlert(alert)"/>
    </div>
    <!-- MOBILE MENU -->
    <div
      class="fixed top-0 w-full h-full z-[80] duration-300 flex flex-col ease-out lg:hidden"
      :class="[isMenuActive ? 'bg-black/20' : 'bg-black/0 pointer-events-none']"
    >
      <div
        class="bg-white rounded-b-2xl px-8 pt-8 duration-300 h-[95%] flex flex-col justify-between"
        :class="[isMenuActive ? '' : '-translate-y-full']"
      >
        <div class="space-y-4">
          <RouterLink
            v-for="(section, i) in visibleSections"
            :key="section.name"
            :to="{ name: section.name }"
            class="flex items-center text-xs font-medium leading-none text-gray-600 duration-300"
            :style="{
              transitionDelay: isMenuActive ? `${150 + 80 * i}ms` : '0ms'
            }"
            :class="[
              isMenuActive ? '' : 'opacity-0 -translate-x-3',
              (section.isAvailable && !section.isCurrent) || section.isCompleted
                ? ''
                : 'pointer-events-none'
            ]"
            @click="isMenuActive = false"
          >
            <div
              class="relative w-8 h-8 mr-3 text-sm font-medium transition duration-300 border-2 rounded-lg shrink-0 font-title"
              :class="[
                i === activeIndex
                  ? section?.isCompleted
                    ? 'text-white border-blue-600 group-hover:text-blue-500 group-hover:duration-100'
                    : 'border-blue-600 text-blue-600'
                  : section.isCompleted
                  ? 'bg-blue-600 text-white border-blue-600'
                  : 'text-gray-400 border-gray-50 ',
                section?.isAvailable &&
                i !== activeIndex &&
                !section?.isCompleted
                  ? 'text-gray-600 border-gray-400'
                  : ''
              ]"
            >
              <BaseIcon
                name="check"
                class="absolute transition duration-300 -translate-x-1/2 top-1/2 left-1/2"
                :class="[
                  section?.isCompleted
                    ? '-translate-y-1/2 group-hover:-translate-y-full group-hover:opacity-0'
                    : '-translate-y-full opacity-0',
                  i === activeIndex && section?.isCompleted
                    ? 'text-blue-500'
                    : 'text-white'
                ]"
                :width="15"
              />
              <span
                class="absolute transition duration-300 -translate-x-1/2 top-1/2 left-1/2"
                :class="[
                  section?.isCompleted
                    ? 'opacity-0 translate-y-0 group-hover:-translate-y-1/2 group-hover:opacity-100'
                    : '-translate-y-1/2'
                ]"
              >
                {{ i + 1 }}
              </span>
            </div>

            <div>
              <p
                class="text-base font-medium"
                :class="[
                  i === activeIndex
                    ? 'text-blue-600'
                    : section.isAvailable
                    ? 'text-gray-600'
                    : 'text-gray-400'
                ]"
              >
                {{ section.title }}
              </p>
            </div>
          </RouterLink>

          <InlineButton
            is-underline
            class="!mt-8"
            :class="[isMenuActive ? 'delay-500' : 'opacity-0 -translate-x-3']"
            :to="{ name: 'logout' }"
            tabindex="999"
          >
            Se déconnecter
          </InlineButton>
        </div>

        <div class="p-4 text-center" @click="isMenuActive = false">
          <BaseIcon name="chevron-down" class="-scale-y-100" :width="22" />
        </div>
      </div>
      <div class="grow" @click="isMenuActive = false" />
    </div>

    <div :style="{ '--duration': `300ms` }">
      <RouterView v-slot="{ Component }">
        <Transition name="transition-onboarding-left" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>

      <div class="fixed hidden bottom-8 left-8 lg:block">
        <InlineButton is-underline :to="{ name: 'logout' }" tabindex="999">
          Se déconnecter
        </InlineButton>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { BaseIcon, InlineButton } from '@/components/atoms'
import { ListAlertCard } from '@/components/organisms'
import {
  useOnboardingStore,
  useFormTemplateStore,
  useAlertsStore
} from '@/stores'
import { storeToRefs } from 'pinia'

const formTemplateStore = useFormTemplateStore()
const { countries, professions_classification_groups, degrees, getTemplate } =
  formTemplateStore

if (
  !countries.length ||
  !professions_classification_groups.length ||
  !degrees.length
) {
  getTemplate()
}

const alertsStore = useAlertsStore()
const { removeAlert } = alertsStore

const onboardingStore = useOnboardingStore()
const { sections, currentSection } = storeToRefs(onboardingStore)

const isMenuActive = ref(false)

const progress = computed(() => {
  const completedSteps =
    currentSection.value?.steps.filter((step) => step.isCompleted) || []
  const availableSteps =
    currentSection.value?.steps.filter((step) => step.isDisplayed) || []

  return Math.max(0.05, completedSteps.length / availableSteps.length)
})

const visibleSections = computed(() => {
  return sections.value.filter((s) => {
    return !s.isSubsidiary
  })
})

const activeIndex = computed(() => {
  return visibleSections.value.findLastIndex(
    (section) =>
      (currentSection?.value?.order ?? 0) >= section.order &&
      !section.isSubsidiary
  )
})
</script>
