<template>
  <div
    v-if="errors && (typeof errors === 'string' || errors.length > 0 && !errors?.some((e) => e.type === 'alert'))"
    class="space-y-3"
    :class="
      errorStyle === 'error-card'
        ? 'p-5 mt-8 mb-3 space-y-1 text-sm rounded-lg bg-red-50'
        : ''
    "
  >
    <div
      v-for="error in errors"
      :key="error.message"
      class="flex items-start text-base leading-5 text-red-500"
    >
      <div
        v-if="errors.length > 1"
        class="h-1 w-1 rounded-full bg-red-500 mr-2.5 mt-2"
      />
      <p>{{ error.message }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { Err } from '@/types'

// Refacto - Voir pour réutiliser l'interface de input.interface.ts
interface BaseError {
  content: Err | Err[] | null
  errorStyle?: 'error-card' | 'error-text'
}

const props = withDefaults(defineProps<BaseError>(), {
  content: null,
  errorStyle: 'error-text'
})

const errors = computed(() => {
  return props.content instanceof Err ? [props.content] : props.content
})
</script>
