import { FawkesUser, Ok, Err, Result } from '@/types'
import { fetchApi } from '@/logic/useApi'

export const fetchFawkesUser = async (): Promise<
  Result<{ user: FawkesUser }>
> => {
  const response = await fetchApi('investor/v1/me')
  if (!response.ok) return response
  const user = response.data as FawkesUser

  if (!user) return new Err('user-not-found')

  localStorage.setItem('isAuth', user.email)
  return new Ok({
    user: new FawkesUser(user)
  })
}
