<template>
  <div class="relative flex items-center">
    <component
      :is="type === 'textarea' ? type : 'input'"
      :id="id"
      v-bind="attrs"
      ref="$input"
      :value="modelValue"
      :type="type"
      class="z-0 w-full border-0 rounded-md focus:ring-0 focus:outline-none"
      :class="[
        color === 'transparent' ? 'shadow-none' : 'shadow-sm',
        bgColor === 'gray' ? isDisabled ? 'bg-gray-200 text-gray-500' : 'bg-gray-100' : (bgColor === 'transparent' ? 'bg-transparent' : ''),
        size === 'lg'
          ? 'pt-4 pb-1 min-h-[65px] text-base'
          : size === 'md'
          ? 'py-2 min-h-[65px]'
          : 'py-2 min-h-[38px] text-sm'
      ]"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :required="isRequired"
      :inputmode="mode"
      :data-testid="dataTestid"
      @input="handleUpdateValue($event)"
      @focus="toggleFocus(true)"
      @blur="toggleFocus(false)"
    />
  </div>
</template>

<script setup lang="ts">
import {
  InputValueText,
  InputMode,
  InputStringType,
  InputNumberType,
  InputDateType
} from '@/types/input2.interface'
import { ref, toRefs } from 'vue'

interface BaseInputProps {
  id: string
  modelValue: InputValueText | null
  type?: InputStringType | InputDateType | InputNumberType
  mode?: InputMode
  size?: 'base' | 'md' | 'lg'
  color?: 'gray' | 'transparent'
  bgColor?: 'gray' | 'white' |'transparent'
  isDisabled?: boolean
  isRequired?: boolean
  placeholder?: string
  attrs?: object
  dataTestid?: string
  min?: string
  max?: string
}

const props = withDefaults(defineProps<BaseInputProps>(), {
  id: Math.random().toString(),
  modelValue: '',
  type: 'text',
  mode: 'text',
  size: 'base',
  isDisabled: false,
  isRequired: false,
  placeholder: '',
  attrs: () => ({}),
  color: 'gray',
  bgColor: 'gray',
  dataTestid: undefined,
  min: undefined,
  max: undefined
})

const { modelValue, type, id, isDisabled, placeholder } = toRefs(props)
const $input = ref<HTMLInputElement | null>(null)

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])

const handleUpdateValue = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}

const isFocused = ref(false)

const toggleFocus = (action: boolean) => {
  isFocused.value = action
  emit(action ? 'focus' : 'blur')
}

const focus = () => {
  if ($input.value) $input.value.focus()
}

defineExpose({
  focus
})
</script>
