import { getStyle } from '@/logic/useStyle'

const styleMapping = [
  {
    property: 'level',
    values: {
      h1: 'font-bold text-[64px]',
      h2: 'font-bold text-5xl',
      h3: 'font-bold text-[40px] leading-[48px]',
      h4: 'font-bold text-[32px]'
    }
  },
  {
    property: 'color',
    values: {
      primary: 'text-primary-800',
      secondary: 'text-secondary-800',
      gray: 'text-gray-800',
      red: 'text-red-800',
      pink: 'text-pink-800',
      yellow: 'text-yellow-800',
      orange: 'text-orange-800',
      green: 'text-green-800',
      blue: 'text-blue-800',
      indigo: 'text-indigo-800',
      purple: 'text-purple-800'
    }
  }
]

export const titleClasses = <T>(props: T) => {
  return getStyle(props, styleMapping)
}
