<template>
  <span
    class="inline-block"
    :style="{ width: isFull ? `100%` : `${width}px` }"
    role="img"
    :aria-label="ariaLabel"
    v-html="icon"
  />
</template>

<script setup lang="ts">
import { inject, ref, toRefs, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    name: string
    width?: number
    isFull?: boolean
    fill?: boolean
    ariaLabel?: string
  }>(),
  {
    width: 20,
    isFull: false,
    fill: false,
    ariaLabel: undefined
  }
)

const { name, fill, width } = toRefs(props)
const icon = ref('')

let icons: object | undefined = inject('icons')

if(!icons) icons = import.meta.glob(`@/assets/svg/**/*.svg`, { as: 'raw' })

const getIcon = async () => {
  if (icons) {
    const path = `/src/assets/svg/${props.name}${props.fill ? '-fill' : ''}.svg`

    // if filled icon not found, default to normal icon
    const iconValue: () => string =
      icons[path as keyof typeof icons] ||
      icons[path.replace('-fill', '') as keyof typeof icons]

    if (iconValue) icon.value = await iconValue()
  }
}

watch(name, getIcon, { immediate: true })
watch(fill, getIcon)
</script>
