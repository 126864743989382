<template>
  <div>
    <div class="flex flex-col py-6" @click="focus">
      <div class="flex space-x-1 md:space-x-4">
        <input
          v-for="(code, index) in codeNumber"
          :id="index === 0 ? id : `${id}-${index}`"
          :key="`code-${code - 1}`"
          :ref="
            (el) => {
              codeInput[code - 1] = el as HTMLInputElement
            }
          "
          v-model="localValue[code - 1]"
          type="number"
          min="0"
          max="9"
          class="h-16 px-2 py-3 text-3xl text-center bg-gray-100 border-transparent rounded-md w-11 md:w-12 placeholder:text-slate-200 focus:outline-none no-arrow"
          :class="[
            codeInputGroupClasses(props),
            isInvalid
              ? 'ring-2 ring-red-500 focus:ring-red-500'
              : 'ring-0 focus:ring-0'
          ]"
          :disabled="isDisabled"
          required
          :placeholder="index.toString()"
          :data-testid="`about-phone-confirmation-${index}`"
          @paste.stop.prevent="splitCode"
          @keyup="keyHandler($event.key, code - 1)"
          @focus="getField(code - 1)"
          @input="handleUpdateValue($event, code - 1)"
        />
      </div>

      <div v-if="isInvalid" class="mt-6 text-red-500">
        Ce code est invalide, veuillez réessayer.
      </div>
    </div>

    <div class="transition" :class="[ timeLeft <= 0 || isTriedAgain ? '' : 'opacity-0 pointer-events-none' ]">
      <span v-if="isTriedAgain" class="font-medium">Code renvoyé ! </span>
      <span v-else>Vous n'avez rien reçu ? </span>

      <span v-if="timeLeft > 0">Renvoi disponible dans {{ timeLeft }} secondes...</span>
      <InlineButton v-else is-underline @click="onTryAgain">Renvoyer le code</InlineButton>
    </div>

    <div v-if="error || helpText" class="flex flex-col mt-2">
      <BaseError v-if="error" :content="error" />
      <BaseHelpText
        v-if="helpText"
        :class="error ? 'pt-1' : ''"
        v-bind="helpText"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, Ref, watch } from 'vue'
import { Label, Err, Optional, HelpText } from '@/types'
import { codeInputGroupClasses } from './CodeInputGroupClasses'
import { BaseError, BaseHelpText, InlineButton } from '@/components/atoms'

interface CodeInputGroupProps {
  id?: string
  modelValue: boolean
  validator: (arg: string) => Promise<boolean>
  codeNumber?: number
  isDisabled?: boolean
  label?: Label | null
  color?: string
  error?: Err | null
  optional?: Optional | null
  helpText?: HelpText | null
}

const props = withDefaults(defineProps<CodeInputGroupProps>(), {
  id: Math.random().toString(),
  codeNumber: 6,
  color: 'gray',
  isDisabled: false,
  label: null,
  error: null,
  optional: null,
  helpText: null
})

const localValue = ref([...Array(props.codeNumber)])

const codeInput = ref<Array<HTMLInputElement | never>>([])
const isError = ref(false)
const isLoading = ref(false)

const emit = defineEmits(['update:modelValue', 'retry'])

const focus = () => {
  if (codeInput.value[0]) codeInput.value[0].focus()
}

const keyHandler = (key: string, field: number) => {
  const regexp = /^[0-9]$/
  if (key === 'Backspace' && !localValue.value[field]) {
    codeInput.value[field - 1]?.focus()
  } else if (regexp.test(key)) {
    codeInput.value[field + 1]?.focus()
  }
}

const splitCode = async (event: ClipboardEvent) => {
  const { clipboardData } = event

  if (clipboardData) {
    const codeSplited = Array.from(
      String(clipboardData.getData('text')),
      Number
    )

    codeSplited.forEach((c, i) => {
      localValue.value[i] = c
    })

    const isValid = await props.validator(codeSplited.join(''))
    emit('update:modelValue', isValid)

    codeInput.value[codeSplited.length - 1]?.focus()
  }
}

const getField = (field: number) => {
  codeInput.value[field]?.select()
}

const isInvalid = computed(() => {
  return (
    props.modelValue === false &&
    localValue.value.join('').length >= props.codeNumber &&
    !isLoading.value
  )
})

const handleUpdateValue = async (event: Event, field: number) => {
  const newValue = [...localValue.value]

  newValue[field] = Number.isInteger(
    Number((event.target as HTMLInputElement).value)
  )
    ? Number((event.target as HTMLInputElement).value)
    : null

  if (newValue.join('').length === props.codeNumber) {
    if (!isError.value) isLoading.value = true

    const isValid = await props.validator(newValue.join(''))
    if (!isValid) isError.value = true

    emit('update:modelValue', isValid)

    isLoading.value = false
  }
}

// TIMER

const timer = ref(10)
const timeLeft = ref(0)
const interval: Ref<any> = ref(null)
const isTriedAgain = ref(false)

const tick = () => {
  timeLeft.value = Math.max(0, timeLeft.value - 1)
}

const isWaiting = computed(() => {
  return timeLeft.value > 0 && timer.value > 0
})

watch(isWaiting, () => {
  if (isWaiting.value === false && interval.value) {
    isTriedAgain.value = false
    timer.value = 0
    clearInterval(interval.value)
  }
})

watch(
  timer,
  () => {
    if (timer.value > 0) {
      timeLeft.value = timer.value
      interval.value = setInterval(tick, 1000)
    }
  },
  { immediate: true }
)

const onTryAgain = () => {
  timer.value = 30
  isTriedAgain.value = true

  emit('retry')
}

defineExpose({
  focus
})
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
</style>
